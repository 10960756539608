<template>
  <form-container class="payment-form-container" title="Your Payment">
    <template #header-icon>
      <icon-payment class="header-icon-payment" />
    </template>

    <template v-if="isSubscribed && !isPaymentCompleted">
      <div class="subscribed-info">
        <template>
          <div class="total-price-box" v-if="!estimatedSubscription">
            <span class="total-text">Total</span>
            <span class="price">{{ totalPrice }}</span>
          </div>

          <div v-else>
            <subscription-summary
              :subscription="estimatedSubscription"
              :contactAdmin="contactAdmin"
              @userUpdatedSubscription="userUpdatedSubscription"
            />
          </div>
        </template>

        <k-tooltip
          v-if="
            isShowingUpdateSubscriptionBtn && isUpdateSubscriptionDisabled.value
          "
          id="tooltip"
          :title="isUpdateSubscriptionDisabled.tooltip"
          :position="'left'"
        >
          <k-button
            class="update-subscription-btn"
            theme-color="primary"
            :disabled="true"
          >
            Update Subscription
          </k-button>
        </k-tooltip>

        <k-button
          v-if="
            isShowingUpdateSubscriptionBtn &&
            !isUpdateSubscriptionDisabled.value
          "
          class="update-subscription-btn"
          theme-color="primary"
          :disabled="isUserSelect0SeatCount"
          @click="handleOpenModalUpdateSubscription"
        >
          Update Subscription
        </k-button>
      </div>
    </template>

    <div id="fsc-embedded-checkout-container" v-show="isCheckoutFormShowing" />

    <subscription-modal
      :isModalShowing="isShowingModalUpdateSubscriptionConfirm"
      @close="isShowingModalUpdateSubscriptionConfirm = false"
      className="k-dialog-wrapper--large"
      title="Update Subscription details confirmation"
    >
      <template #description>
        Are you sure you want to update your subscription details?
        <br />
        Keep in mind that you can file a refund claim if an unwanted transaction
        is made by us.
        <br />
        We will work with the evidence you provided to determine if the
        transaction is refundable according to our policies
        <br />
        <span v-html="contactAdmin" />
      </template>

      <template #action>
        <subscription-summary
          v-if="estimatedSubscription"
          :subscription="estimatedSubscription"
          :contactAdmin="contactAdmin"
        />

        <div class="mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="isShowingModalUpdateSubscriptionConfirm = false"
          >
            Cancel
          </k-button>
          <k-button
            class="me-2"
            theme-color="primary"
            @click="handleUpdateSubscriptionClick"
          >
            Confirm
          </k-button>
        </div>
      </template>
    </subscription-modal>

    <div class="no-form" :class="{ hide: showPaymentForm || isSubscribed }">
      <span>Select regions to show the payment form</span>
    </div>
  </form-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormContainer from '@/components/tenantAdmin/formContainer.vue';
import SubscriptionSummary from '@/components/tenantAdmin/subscriptionSummary.vue';
import SubscriptionModal from '@/components/tenantAdmin/components/SubscriptionModal/SubscriptionModal.vue';
import getContactAdminMixin from '@/components/setting/mixins/contactAdmin';
import updateOrderMixin from './mixins/updateOrder';

export default {
  components: { FormContainer, SubscriptionSummary, SubscriptionModal },
  mixins: [getContactAdminMixin, updateOrderMixin],
  props: {
    isShowingUpdateSubscriptionBtn: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    isUpdateSubscriptionDisabled: {
      type: Object,
      default: function () {
        return {
          value: false,
          tooltip: ''
        };
      }
    },
    estimatedSubscription: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
  data() {
    return {
      isShowingModalUpdateSubscriptionConfirm: false,
      isUserUpdatedSubscription: false
    };
  },
  computed: {
    ...mapGetters('fastspring', [
      'showPaymentForm',
      'selectedBundle',
      'isPaymentCompleted',
      'totalPrice',
      'extraSeatCount',
      'selectedRegions',
      'subscription'
    ]),
    isSubscribed() {
      return !!(
        this.subscription && this.subscription.fastspringSubscriptionId
      );
    },
    formTitle() {
      return this.isPaymentCompleted ? 'Order Confirmed' : 'Your Payment';
    },
    isCheckoutFormShowing() {
      if (!this.selectedRegions || this.selectedRegions.length === 0) {
        return false;
      }

      if (this.isSubscribed) return this.isPaymentCompleted;

      return true;
    },
    isUserSelect0SeatCount() {
      const modifiedSeatCount =
        Number(((this.selectedBundle || {}).attributes || {}).seatCount || 0) +
        this.extraSeatCount;

      return !modifiedSeatCount || Number(modifiedSeatCount) === 0;
    }
  },
  watch: {
    selectedRegions(newValue) {
      this.selectedRegions.length = newValue.length;
    }
  },
  methods: {
    ...mapActions('fastspring', ['updateSubscription', 'updateCart']),
    async handleUpdateSubscriptionClick() {
      await this.updateSubscription(this.selectedBundle.subscription);
      this.isShowingModalUpdateSubscriptionConfirm = false;
    },
    async handleOpenModalUpdateSubscription() {
      const cart = await this.updateCart();
      await this.updateOrder(cart);
      this.isShowingModalUpdateSubscriptionConfirm = true;
    },
    userUpdatedSubscription(value) {
      this.isUserUpdatedSubscription = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-form-container {
  flex: 1;
  max-width: 528px;

  ::v-deep .header-icon-payment {
    width: 21px;
    height: 16px;
  }
}

.no-form {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  z-index: 9999999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--component-bg);

  &.hide {
    transition: all 500ms;
    opacity: 0;
    pointer-events: none;
  }
}

.subscribed-info {
  padding: 28px 20px;
  min-width: 500px;
}

.total-price-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: var(--primary);
  color: var(--button-text);
  padding: 19px 10px 17px;

  .total-text {
    color: var(--button-text);
    font-size: 18px;
    font-weight: 600;
    margin-right: 12px;
  }

  .price {
    color: rgb(0, 189, 165);
    font-size: 18px;
    font-weight: 600;
  }
}

.update-subscription-btn {
  width: 100%;
  height: 42px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 34px;
}

#fsc-embedded-checkout-container {
  height: 100% !important;
}
</style>
