<template>
  <div>
    <div class="row header align-items-center mb-6">
      <div class="col-6">
        <h3>Manage User Creation Requests</h3>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <k-button
          class="ms-2"
          theme-color="primary"
          fill-mode="solid"
          @click="showDialog"
        >
          Add User Request
        </k-button>
      </div>
    </div>

    <k-grid
      :data-items="dataResult"
      :pageable="true"
      :sortable="true"
      :sort="sort"
      :skip="skip"
      :take="take"
      :columns="columns"
      @datastatechange="dataStateChange"
      @rowclick="rowClick"
      scrollable="none"
    >
      <template v-slot:exchange_country="{ props }">
        <td :title="props.dataItem.regionName">
          {{ props.dataItem.regionName | formatRegion }}
        </td>
      </template>

      <template v-slot:displayName="{ props }">
        <td>
          <p
            class="mb-0"
            v-if="
              props.dataItem.personList && props.dataItem.personList.length > 0
            "
          >
            {{ props.dataItem.personList[0].displayName }}
          </p>
          <p class="mb-0" v-else>N/A</p>
        </td>
      </template>

      <template v-slot:email="{ props }">
        <td>
          <p
            class="mb-0"
            v-if="
              props.dataItem.personList && props.dataItem.personList.length > 0
            "
          >
            {{ props.dataItem.personList[0].email }}
          </p>
          <p class="mb-0" v-else>N/A</p>
        </td>
      </template>

      <template v-slot:lucaAccessible="{ props }">
        <td>
          <p
            class="mb-0"
            v-if="
              props.dataItem.personList && props.dataItem.personList.length > 0
            "
          >
            {{ props.dataItem.personList[0].lucaAccessible ? 'Yes' : 'No' }}
          </p>
          <p class="mb-0" v-else>N/A</p>
        </td>
      </template>

      <template v-slot:actionTemplate="{ props }">
        <td>
          <actions-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showEditDialog(props.dataItem)"
              >Edit User Request</k-button
            >
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showDeleteConfirmationDialog(props.dataItem)"
              >Delete User Request</k-button
            >
          </actions-button>
        </td>
      </template>
    </k-grid>

    <k-dialog v-if="dialog.show" :title="' '" @close="closeDialog">
      <div class="row mb-3">
        <div class="col-12 text-center" v-html="dialog.title"></div>
      </div>
      <form @submit="save">
        <div
          style="
            overflow-y: auto;
            max-height: 500px;
            overflow-x: hidden;
            margin-bottom: 16px;
          "
        >
          <div class="row">
            <div class="col-12 mb-3">
              Select Tenant
              <div>
                <k-dropdown-native
                  :data-items="tenantList"
                  :text-field="'companyId'"
                  :data_item-key="'id'"
                  :value="selectedTenant"
                  @change="changeSelectedTenant"
                >
                </k-dropdown-native>
              </div>
            </div>
          </div>

          <template v-if="selectedTenant">
            <div class="row">
              <div class="col-12">
                User Name
                <k-inputs
                  v-model="entity.personList[0].displayName"
                  name="displayName"
                  :required="true"
                  placeholder="Eg. John Doe"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                Email
                <k-inputs
                  v-model="entity.personList[0].email"
                  name="email"
                  :required="true"
                  placeholder="Eg. john.doe@mail.com"
                  :pattern="'^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+([a-zA-Z]){2,7}$'"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3">
                Access to Luca
                <div>
                  <k-dropdown-native
                    :data-items="accessOptions"
                    :text-field="'text'"
                    :data_item-key="'id'"
                    :value="entity.personList[0].lucaAccessible"
                    @change="changeLucaAccessible"
                  >
                  </k-dropdown-native>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                Start Access
                <k-date-picker
                  v-model="entity.startDate"
                  name="startDate"
                  :required="true"
                  class="mb-0"
                  :style="errors.startDate && { borderColor: 'red' }"
                />
                <span v-if="errors.startDate" style="color: red">{{
                  errors.startDate
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3">
                End Access
                <k-date-picker
                  v-model="entity.endDate"
                  name="endDate"
                  :required="true"
                  class="mb-0"
                  :style="errors.endDate && { borderColor: 'red' }"
                />
                <span v-if="errors.endDate" style="color: red">{{
                  errors.endDate
                }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <k-dialog
      v-if="deleteConfirmationDialog.show"
      :title="' '"
      @close="closeDeleteConfirmationDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            Are you sure you want to delete
            {{ deleteConfirmationDialog.dataItem.organizationName }}?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeDeleteConfirmationDialog"
            >Close</k-button
          >
          <k-button
            theme-color="primary"
            @click="
              deleteRequest(deleteConfirmationDialog.dataItem.featureRequestId)
            "
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>

    <k-notification
      ref="popupNotification"
      :allow-hide-after="1000"
      :auto-hide-after="7000"
      :button="true"
      :hide-on-click="true"
      class="mt-2"
    >
    </k-notification>
  </div>
</template>

<script>
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { format } from 'date-fns';
import ActionsButton from './components/actionsButton.vue';
import cloneDeep from 'lodash/cloneDeep';
import startOfDay from 'date-fns/startOfDay';

export default {
  components: {
    ActionsButton
  },
  data() {
    return {
      tRegion: [],
      dRegion: null,
      entityList: [],
      entity: {
        map: {}
      },
      skip: 0,
      take: 10,
      filter: null,
      columns: [
        { field: 'organizationName', title: 'Organization Name' },
        { field: 'organizationUrl', title: 'Organization URL' },
        { field: 'displayName', title: 'User Name', cell: 'displayName' },
        { field: 'email', title: 'Email', cell: 'email' },
        {
          field: 'lucaAccessible',
          title: 'Luca Accessible',
          cell: 'lucaAccessible'
        },
        { field: 'startDate', title: 'Access Start Date' },
        { field: 'endDate', title: 'Access End Date' },
        { field: '', title: '', width: 200, cell: 'actionTemplate' }
      ],
      dataResult: [],
      allRegion: [],
      dialog: {
        show: false,
        title: null
      },
      deleteConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      tenantList: [],
      selectedTenant: null,
      refetchInterval: null,
      showSelectRegion: true,
      accessOptions: [
        { id: true, text: 'Yes' },
        { id: false, text: 'No' }
      ],
      originalEntity: null,
      errors: {}
    };
  },
  watch: {
    tRegion(value) {
      if (value.length == 0) {
        this.dRegion = null;
      } else {
        this.dRegion = ' ';
      }
    },
    'entity.startDate'(startDate) {
      this.validateForm({ ...this.entity, startDate });
    },
    'entity.endDate'(endDate) {
      this.validateForm({ ...this.entity, endDate });
    }
  },
  async mounted() {
    await this.getRequestedTenantList();
    await this.getTenantList();
    this.popupNotificationWidget = this.$refs.popupNotification.kendoWidget();

    if (this.$route.query && this.$route.query.page) {
      const currentPageSkip = this.take * (Number(this.$route.query.page) - 1);

      if (this.dataResult.total > currentPageSkip) {
        this.skip = this.take * (Number(this.$route.query.page) - 1);
        this.dataResult = process(this.entityList, {
          skip: this.skip,
          take: this.take,
          sort: this.sort
        });

        return;
      }

      this.$router.push({
        name: this.$route.name
      });
    }

    this.setRefetchInterval();
  },
  unmounted() {
    clearInterval(this.refetchInterval);
  },
  methods: {
    changeLucaAccessible(event) {
      this.entity.personList = [
        {
          ...this.entity.personList?.[0],
          lucaAccessible: event.value
        }
      ];
    },
    changeSelectedTenant(event) {
      if (event.value.id) {
        this.selectedTenant = event.value;
        this.setTenantDetail({
          ...event.value
        });
        this.triggerSelectRegion();
        return;
      }
      this.selectedTenant = null;
      this.entity = {};
      this.triggerSelectRegion();
    },
    triggerSelectRegion() {
      this.showSelectRegion = false;
      this.$nextTick(() => {
        this.showSelectRegion = true;
      });
    },
    setRefetchInterval() {
      this.refetchInterval = setInterval(() => {
        this.getRequestedTenantList();
      }, 60000 * 5);
    },
    selectedRegion(value) {
      this.entity.region = value;
    },
    async getRequestedTenantList() {
      try {
        const response = await axios.get('/tenant/request-future-create/list');

        this.entityList = response.data?.filter(
          ({ requestType }) => requestType === 'USER'
        );
        this.dataResult = process(this.entityList, {
          skip: this.skip,
          take: this.take,
          sort: this.sort
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getTenantList() {
      try {
        const response = await axios.post('/tenant/list');
        this.tenantList = [{ id: '', companyId: 'None' }, ...response.data];
      } catch (e) {
        console.log(e);
      }
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
      this.dataResult = process(this.entityList, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter
      });

      this.$router.push({
        name: this.$route.name,
        query: { page: Math.floor(this.skip / this.take) + 1 }
      });
    },
    rowClick() {
      this.windowVisible = true;
    },
    search() {},
    goTo(props) {
      if (props.dataItem) {
        this.$router.push('tenant-request/' + props.dataItem.featureRequestId);
      }
    },
    async fetchAllregion() {
      const response = await axios.post('/dashboard/allRegion');
      this.allRegion = response.data;
    },
    async showDialog() {
      await this.fetchAllregion();
      this.entity.allRegion = this.allRegion;
      this.dialog.title = 'Add Request';
      this.dialog.show = true;
    },
    async showEditDialog(dataItem) {
      await this.fetchAllregion();
      this.dialog.title = 'Edit Request';
      this.setTenantDetail(dataItem);
      if (dataItem.companyId) this.selectedTenant = dataItem;
      this.dialog.show = true;
    },
    setTenantDetail(dataItem) {
      let selectedRegion = dataItem.selectedRegion;
      this.entity = cloneDeep(dataItem);
      this.entity.allRegion = this.allRegion;
      this.entity.region = selectedRegion;
      this.entity.startDate = this.toUTC(new Date(dataItem.startDate));
      this.entity.endDate = this.toUTC(new Date(dataItem.endDate));
      this.entity.personList = [
        {
          ...this.entity.personList?.[0],
          lucaAccessible: this.accessOptions.find(
            ({ id }) => id == this.entity.personList?.[0]?.lucaAccessible
          )
        }
      ];
    },
    closeDialog() {
      this.dialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
      this.selectedTenant = null;
    },
    save(event) {
      event.preventDefault();

      const isFormValid = this.validateForm(this.entity);
      if (!isFormValid) return;

      this.saving();
    },
    validateForm(values) {
      const { startDate, endDate } = values || {};

      this.errors = {};

      if (startDate && endDate) {
        if (startOfDay(startDate) < startOfDay(new Date())) {
          this.errors['startDate'] = 'Start Date cannot in the past';
        }
        if (startOfDay(endDate) < startOfDay(new Date())) {
          this.errors['endDate'] = 'End Date cannot in the past';
        }
        if (endDate <= startDate) {
          this.errors['startDate'] =
            'Start Date cannot after or equal End Date';
        }
      }

      return Object.values(this.errors).length === 0;
    },
    toUTC(date) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds()
      );
    },
    async saving() {
      try {
        this.originalEntity = cloneDeep(this.entity);
        let formData = cloneDeep(this.entity);
        formData = [];
        formData = [...this.tRegion];
        let regionsWithName = [];

        await this.fetchAllregion();

        regionsWithName = [...this.allRegion];
        regionsWithName = regionsWithName.filter((reg) =>
          formData.includes(reg.exchange_countryID)
        );

        // Send just region id to backend
        this.entity.region = [];
        this.entity.selectedRegion = [];
        regionsWithName.forEach((reg) => {
          let id = parseInt(reg.exchange_countryID);
          this.entity.region.push(id);
          this.entity.selectedRegion.push(reg);
        });

        if (this.entity.selectAllRegions) {
          this.entity.regionName = 'All';
        } else {
          this.entity.regionName = this.entity.selectedRegion
            .map(({ exchange_country }) => exchange_country)
            .join(', ');
        }

        this.entity.requestType = 'USER';

        // eslint-disable-next-line no-unused-vars
        const { id, ...entity } = this.entity || {};

        const newEntity = cloneDeep(entity);

        newEntity.startDate = this.toUTC(
          new Date(newEntity.startDate)
        ).toISOString();
        newEntity.endDate = this.toUTC(
          new Date(newEntity.endDate)
        ).toISOString();
        newEntity.personList = [
          {
            ...newEntity.personList?.[0],
            lucaAccessible: newEntity.personList?.[0]?.lucaAccessible?.id
          }
        ];

        const tenantResponse = await axios.post(
          '/tenant/request-future-create',
          newEntity
        );

        if (tenantResponse.status == 200) {
          this.closeDialog();
          await this.getRequestedTenantList();
          clearInterval(this.refetchInterval);
          this.setRefetchInterval();
        }
      } catch (e) {
        console.log(e);
        this.entity = cloneDeep(this.originalEntity);
      }
    },
    showDeleteConfirmationDialog(dataItem) {
      this.deleteConfirmationDialog.dataItem = dataItem;
      this.deleteConfirmationDialog.show = true;
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },

    deleteRequest(featureRequestId) {
      const vm = this;
      axios
        .post(`/tenant/request-future-create/delete/${featureRequestId}`)
        .then((response) => {
          if (response.status == 200) {
            this.getRequestedTenantList();
          } else if (response.status == 204) {
            vm.popupNotificationWidget.show(
              'This request cannot be deleted since the user is still exists. If you want to delete this deleteRequest, please delete all user associated with this deleteRequest.',
              'error'
            );
          } else if (response.status == 202) {
            vm.popupNotificationWidget.show(response.data, 'error');
          }

          this.closeDeleteConfirmationDialog();
        });
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return null;
      return format(value, 'yyyy-MM-dd');
    },
    formatISODate: function (value) {
      if (!value) return null;
      return format(new Date(value), 'yyyy-MM-dd');
    },
    formatRegion: function (value) {
      if (!value) return null;

      const valueToArray = value && value.length > 0 ? value.split(', ') : [];

      return valueToArray.length > 1 ? '(multiple) ..' : value;
    }
  }
};
</script>

<style lang="scss" scoped>
.search-input {
  margin: calc(var(--bs-gutter-x) * 0.5);
  border: 1px solid #495057;
  border-radius: 0.25rem;
  width: fit-content;
}
</style>
