import Vue from 'vue';
import router from './plugins/router';
import store from './plugins/store';
import i18n from './plugins/i18n';
import axios from 'axios';
import Vidle from 'v-idle';
import application from './App.vue';
import './assets/sass/site.css';
import 'bootstrap';
import errorNotificationMixin from '@/shared/mixins/errorNotification';

export const EventBus = new Vue();

/* START - Global Component Registration */
import { Button } from '@progress/kendo-vue-buttons';
import { Dialog } from '@progress/kendo-vue-dialogs';
import { PanelBar } from '@progress/kendo-vue-layout';
import { ComboBox } from '@progress/kendo-dropdowns-vue-wrapper';
import {
  DropDownList as DropDownListNative,
  MultiSelect
} from '@progress/kendo-vue-dropdowns';
import { Form, Field, FormElement } from '@progress/kendo-vue-form';
import { Grid, GridNoRecords } from '@progress/kendo-vue-grid';
import {
  Input,
  RadioButton,
  Checkbox,
  NumericTextBox,
  TextArea
} from '@progress/kendo-vue-inputs';
import { Popup } from '@progress/kendo-vue-popup';
import { RangeSlider } from '@progress/kendo-inputs-vue-wrapper';
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { Tooltip, Notification } from '@progress/kendo-popups-vue-wrapper';
import '@progress/kendo-ui';
import { Editor } from '@progress/kendo-vue-editor';
import {
  Chart,
  ChartArea,
  ChartTooltip,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem
} from '@progress/kendo-vue-charts';

import formInput from '@/components/form/formInput';
import formSelect from '@/components/form/formSelect';
import IconLogo from '@/components/icons/IconLogo';
import IconLogoHeader from '@/components/icons/IconLogoHeader';
import IconLogoReportHeader from '@/components/icons/IconLogoReportHeader';
import IconWarning from '@/components/icons/IconWarning';
import IconInfo from '@/components/icons/IconInfo';
import IconInfoPopup from '@/components/icons/IconInfoPopup';
import IconAccount from '@/components/icons/IconAccount';
import IconClose from '@/components/icons/IconClose';
import IconClose2 from '@/components/icons/IconClose2';
import IconEdit from '@/components/icons/IconEdit';
import IconTrash from '@/components/icons/IconTrash';
import IconCheck from '@/components/icons/IconCheck';
import IconCheckGreen from '@/components/icons/IconCheckGreen';
import IconCircleRed from '@/components/icons/IconCircleRed';
import IconCircleGreen from '@/components/icons/IconCircleGreen';
import IconCircleGray from '@/components/icons/IconCircleGray';
import IconCircleGray2 from '@/components/icons/IconCircleGray2';
import IconFileArrowDown from '@/components/icons/IconFileArrowDown';
import IconOrderDetails from '@/components/icons/IconOrderDetails';
import IconPayment from '@/components/icons/IconPayment';
import IconLogout from '@/components/icons/IconLogout.vue';
import IconUpdateSubscription from '@/components/icons/IconUpdateSubscription.vue';
import IconPushNotification from '@/components/icons/IconPushNotification.vue';
import IconDots from '@/components/icons/IconDots';
import splashScreen from '@/components/splashScreen';
import IconAdd from '@/components/icons/IconAdd.vue';
import IconCheckBold from '@/components/icons/IconCheckBold.vue';
import IconRenew from '@/components/icons/IconRenew.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
import IconChange from '@/components/icons/IconChange.vue';
import IconRemove from '@/components/icons/IconRemove.vue';
import IconArrowLeft from '@/components/icons/IconArrowLeft';
import VueCookies from 'vue-cookies';
import { VueRecaptcha } from 'vue-recaptcha';
import 'hammerjs';
import themeMixins from './shared/mixins/theme';

Vue.component('k-form', Form);
Vue.component('k-form-element', FormElement);
Vue.component('k-field', Field);
Vue.component('k-input', formInput);
Vue.component('k-inputs', Input);
Vue.component('k-textarea', TextArea);
Vue.component('k-radio', RadioButton);
Vue.component('k-checkbox', Checkbox);
Vue.component('k-input-num', NumericTextBox);
Vue.component('k-select', formSelect);
Vue.component('k-button', Button);
Vue.component('k-grid', Grid);
Vue.component('k-grid-no-rec', GridNoRecords);
Vue.component('k-dialog', Dialog);
Vue.component('k-dropdown-native', DropDownListNative);
Vue.component('k-multi-select', MultiSelect);
Vue.component('k-panelbar', PanelBar);
Vue.component('k-range-slider', RangeSlider);
Vue.component('k-date-picker', DatePicker);
Vue.component('k-combobox', ComboBox);
Vue.component('k-tooltip', Tooltip);
Vue.component('k-notification', Notification);
Vue.component('k-popup', Popup);
Vue.component('icon-logo', IconLogo);
Vue.component('icon-logo-header', IconLogoHeader);
Vue.component('icon-logo-report-header', IconLogoReportHeader);
Vue.component('icon-warning', IconWarning);
Vue.component('icon-info', IconInfo);
Vue.component('icon-info-popup', IconInfoPopup);
Vue.component('icon-account', IconAccount);
Vue.component('icon-close', IconClose);
Vue.component('icon-close2', IconClose2);
Vue.component('icon-edit', IconEdit);
Vue.component('icon-trash', IconTrash);
Vue.component('icon-check', IconCheck);
Vue.component('icon-check-green', IconCheckGreen);
Vue.component('icon-circle-red', IconCircleRed);
Vue.component('icon-circle-green', IconCircleGreen);
Vue.component('icon-circle-gray', IconCircleGray);
Vue.component('icon-circle-gray2', IconCircleGray2);
Vue.component('icon-file-arrow-down', IconFileArrowDown);
Vue.component('icon-order-details', IconOrderDetails);
Vue.component('icon-payment', IconPayment);
Vue.component('icon-logout', IconLogout);
Vue.component('icon-update-subscription', IconUpdateSubscription);
Vue.component('icon-push-notification', IconPushNotification);
Vue.component('icon-add', IconAdd);
Vue.component('icon-remove', IconRemove);
Vue.component('icon-check-bold', IconCheckBold);
Vue.component('icon-renew', IconRenew);
Vue.component('icon-search', IconSearch);
Vue.component('icon-change', IconChange);
Vue.component('icon-dots', IconDots);
Vue.component('icon-arrow-left', IconArrowLeft);
Vue.component('splash-screen', splashScreen);
Vue.component('vue-recaptcha', VueRecaptcha);
Vue.component('k-editor', Editor);

Vue.component('k-chart', Chart);
Vue.component('k-chart-area', ChartArea);
Vue.component('k-chart-tooltip', ChartTooltip);
Vue.component('k-chart-legend', ChartLegend);
Vue.component('k-chart-series', ChartSeries);
Vue.component('k-chart-series-item', ChartSeriesItem);
Vue.component('k-chart-valueaxis', ChartValueAxis);
Vue.component('k-chart-valueaxis-item', ChartValueAxisItem);
Vue.component('k-chart-categoryaxis', ChartCategoryAxis);
Vue.component('k-chart-categoryaxis-item', ChartCategoryAxisItem);

/* END - Global Component Registration */

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

Vue.config.productionTip = false;

Vue.prototype.$window = window;
Vue.prototype.$environment = process.env.VUE_ENVIRONMENT;
Vue.prototype.$recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITEKEY;

if (window.location.origin.includes('localhost')) {
  axios.defaults.baseURL = 'http://localhost:9292/api';
} else {
  axios.defaults.baseURL = '/api';
}

Vue.use(Vidle);
Vue.use(VueCookies);

new Vue({
  router,
  store,
  i18n,
  mixins: [errorNotificationMixin, themeMixins],
  render: (display) => display(application),
  data() {
    return {
      loader: {
        count: 0,
        stroke: 7,
        diameter: 50,
        value: false,
        isShow: true,
        dynamicFilter: true
      },
      initTinyMce: {
        apiKey: 'qy81v1mz6tnui81094j9zgdry5uu8lgb3zwkc87t33yh3z83',
        toolbar: {
          height: 300,
          menubar: true,
          plugins: [
            'textcolor',
            'code',
            'link',
            'image',
            'lists',
            'media',
            'paste'
          ],
          paste_as_text: true,
          toolbar_mode: 'wrap',
          toolbar:
            'formatselect | bold italic underline strikethrough | \
						alignleft aligncenter alignright alignjustify| blockquote code | \
						bullist numlist | outdent indent | forecolor backcolor | link image media | removeformat | pastetext | help'
        }
      }
    };
  },
  watch: {
    'loader.value': function (val) {
      if (val == false) {
        this.loader.dynamicFilter = true;
      }
    }
  },
  created: function () {
    this.setupTheme();

    // Request
    axios.interceptors.request.use(
      (request) => {
        this.load();

        const authToken = store.getters.principal
          ? store.getters.principal.idToken
          : '';

        if (authToken) {
          request.headers.common['Authorization'] = `Bearer ${authToken}`;
        } else {
          delete request.headers.common['Authorization'];
        }

        return request;
      },
      (error) => {
        this.unload();
        this.handleShowErrorNotification(error);

        return Promise.reject(error);
      }
    );

    // Response
    axios.interceptors.response.use(
      (response) => {
        this.unload();

        if (response.data && response.data.type === 'NOTIFICATION') {
          store.commit('showNotification', {
            title: 'Notification',
            content: response.data
          });
        }

        return response;
      },
      (error) => {
        this.unload();
        this.handleShowErrorNotification(error);

        return Promise.reject(error);
      }
    );
  },
  methods: {
    load() {
      this.loader.count++;
      this.loader.value = true;
    },
    unload() {
      this.loader.count--;
      if (this.loader.count < 0) {
        this.loader.count = 0;
      }
      this.loader.value = this.loader.count > 0;
    },
    clone(object) {
      return JSON.parse(JSON.stringify(object));
    },
    formatTimer(seconds) {
      const convert = function (x) {
        return x < 10 ? '0' + x : x;
      };

      return (
        convert(parseInt(seconds / (60 * 60))) +
        ':' +
        convert(parseInt((seconds / 60) % 60)) +
        ':' +
        convert(seconds % 60)
      );
    }
  }
}).$mount('#app');
