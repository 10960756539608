<template>
  <k-form-element>
    <div
      v-if="kendoForm.visited && kendoForm.touched && kendoForm.errors.code"
      class="row col-12 error"
    >
      <span><icon-warning /></span>
      <span v-text="$t(kendoForm.errors.code)"></span>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <p class="mfa-info">
          A six-digit authentication code has been sent to
          <span
            v-if="$store.state.verification.verifyType === 1"
            class="text-primary"
            >{{ maskingEmail($store.state.verification.email) }}</span
          >. Please add this below to satisfy our security requirements.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <k-field
          :name="'code'"
          type="text"
          autofocus="autofocus"
          :max-length="'6'"
          :component="'myTemplate'"
        >
          <template v-slot:myTemplate="{ props }">
            <k-input
              id="code"
              class="ta-input--mfa"
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
            />
          </template>
        </k-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <k-button type="button" fill-mode="link" @click="resendCode"
          >Resend Code</k-button
        >
        <k-button
          type="submit"
          :disabled="!kendoForm.allowSubmit"
          theme-color="primary"
        >
          Login
        </k-button>
      </div>
    </div>
  </k-form-element>
</template>

<script>
export default {
  inject: {
    kendoForm: { default: {} }
  },
  mounted() {
    document.getElementById('code').focus();
  },
  methods: {
    resendCode() {
      this.kendoForm.onFormReset();
      this.$emit('resendCode');
    },
    maskingEmail(email) {
      if (email) {
        let account = email.split('@');
        let endStr = account[0].slice(-2);
        if (account[0].length > 4) {
          return (
            account[0].slice(0, 2) +
            endStr.padStart(account[0].length - 2, '*') +
            '@' +
            account[1]
          );
        } else {
          return endStr.padStart(account[0].length, '*') + '@' + account[1];
        }
      }
    }
  }
};
</script>
