<template>
  <div>
    <div>
      <div class="row dashboard__panel">
        <div class="d-flex align-items-center dashboard__panel--header">
          <div class="col-4">
            <span class="dashboard__panel--header--title"
              >Risk Score History</span
            >
          </div>
          <div class="col-8 d-flex justify-content-end align-items-center">
            <icon-circle-red />{{ name }}
            <template v-if="popupsMap[POPUP_ID.history]">
              <icon-circle-gray />Average
              <span
                class="d-flex align-center k-link px-1"
                @click="showHistoryPopup"
                ><icon-info
              /></span>
            </template>
          </div>
        </div>

        <div
          class="row"
          v-if="
            data.year.length > 0 && (average.length > 0 || score.length > 0)
          "
        >
          <div class="col">
            <k-chart
              :style="{ height: '260px' }"
              :renderAs="'canvas'"
              :transitions="false"
            >
              <k-chart-area :background="'transparent'" />
              <k-chart-tooltip :visible="true" />
              <k-chart-legend :visible="true" />
              <k-chart-valueaxis>
                <k-chart-valueaxis-item
                  :majorGridLines="{ visible: false }"
                  :visible="true"
                  :max="100"
                  :min="0"
                  :majorUnit="20"
                  :line="{ color: '#8698ac' }"
                  :labels="{ color: '#8698ac' }"
                />
              </k-chart-valueaxis>
              <k-chart-categoryaxis>
                <k-chart-categoryaxis-item
                  :majorGridLines="{ visible: false }"
                  :visible="true"
                  :justified="score.length > 1 ? true : false"
                  :categories="category"
                  :line="{ color: '#8698ac' }"
                  :labels="{ color: '#8698ac' }"
                />
              </k-chart-categoryaxis>
              <k-chart-series>
                <k-chart-series-item
                  :type="'line'"
                  :data-items="average"
                  :markers="{ visible: average.length > 1 ? false : true }"
                  :color="'#acacac'"
                  :stack="false"
                ></k-chart-series-item>
                <k-chart-series-item
                  :type="'line'"
                  :data-items="score"
                  :markers="{ visible: score.length > 1 ? false : true }"
                  :color="'#F24A55'"
                  :stack="false"
                ></k-chart-series-item>
              </k-chart-series>
            </k-chart>
          </div>
        </div>
        <div
          v-else
          class="row align-items-center justify-content-center"
          style="height: 260px"
        >
          No data available for this year
        </div>
      </div>

      <div
        class="dashboard__popup dashboard__history-popup dashboard__panel--overflow"
        v-if="showHP && popupsMap[POPUP_ID.history]"
        v-click-outside="onClickOutside"
      >
        <div class="row pb-6">
          <div class="col-1 pt-2"><icon-info-popup></icon-info-popup></div>
          <div class="col-6 header d-flex justify-content-start pt-1">
            Risk Score History
          </div>
          <div class="col d-flex justify-content-end" @click="hideHistoryPopup">
            <icon-close2></icon-close2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 gray">{{ popupsMap[POPUP_ID.history] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { POPUP_ID } from '@/shared';

export default {
  props: ['data', 'name', 'popupsMap', 'riskHistoryData'],
  watch: {
    data: {
      handler: function (val) {
        this.getCategory(val);
      },
      deep: true,
      loaded: false
    }
  },
  data() {
    return {
      normalLine: 'normal',
      showHP: false,
      category: [],
      score: [],
      average: []
    };
  },
  computed: {
    POPUP_ID() {
      return POPUP_ID;
    }
  },
  mounted() {
    if (this.data) {
      this.getCategory(this.data);
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    getCategory(val) {
      const category = [];
      const score = [];
      const average = [];

      const years = val.year;

      if (years.length > 0) {
        const earliestYear = years[0];
        const latestYear = years[years.length - 1];
        const yearsFromEarliestToLatest = [];

        //Create an arrays of years from the earliest year to the latest year
        let tempYear = earliestYear;
        while (tempYear <= latestYear) {
          yearsFromEarliestToLatest.push(tempYear);
          tempYear++;
        }

        for (let i = yearsFromEarliestToLatest.length - 1; i >= 0; i--) {
          const currentYear = yearsFromEarliestToLatest[i];

          //If number of years bigger than 7. On x-axis, we show a year then show a blank value and show the next year and so on.
          if (yearsFromEarliestToLatest.length > 7) {
            //We always show the last year, so need to check for the value of the last year to determine if the next year is show or not.
            if ((yearsFromEarliestToLatest.length - 1) % 2 === 0) {
              if (i % 2 === 0) {
                category.push(currentYear);
              } else {
                category.push('');
              }
            } else if (i % 2 === 0) {
              category.push('');
            } else {
              category.push(currentYear);
            }
            //Or else we show every years on x-axis
          } else {
            category.push(currentYear);
          }

          //Fill the gap
          const currentYearData = this.riskHistoryData.find(
            ({ year }) => year === currentYear
          );
          if (currentYearData) {
            score.push(currentYearData.score);
            average.push(currentYearData.average);
          } else {
            score.push(null);
            average.push(null);
          }
        }

        this.category = category.reverse();
        this.score = score.reverse();
        this.average = average.reverse();

        if (this.score.every((value) => !value)) {
          this.score = [];
        }

        if (this.average.every((value) => !value)) {
          this.average = [];
        }
      }

      if (this.data.length > 0) {
        this.$forceUpdate();
      }
    },
    onClickOutside() {
      this.hideHistoryPopup();
    },
    showHistoryPopup() {
      if (!this.showHP) {
        this.showHP = true;
      }
    },
    hideHistoryPopup() {
      if (this.showHP) {
        this.showHP = false;
      }
    }
  }
};
</script>
