<template>
  <div class="tenant-admin-header">
    <div class="container d-flex justify-content-between align-items-center">
      <icon-logo-header class="tenant-admin-header__logo" />

      <div>
        <k-button v-if="isSubscribed" class="me-3" @click="goTo('/dashboard')">
          Dashboard
        </k-button>
        <k-button @click="goTo('/manageUsers')" class="me-3">
          Manage Users
        </k-button>
        <theme-toggle />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ThemeToggle from '../themeToggle.vue';

export default {
  computed: {
    ...mapGetters('fastspring', ['isSubscribed'])
  },
  components: {
    ThemeToggle
  },
  methods: {
    ...mapMutations('fastspring', [
      'CLEAR_SELECTED_REGIONS',
      'SET_IS_PAYMENT_COMPLETED',
      'SET_IS_ORDER_PREVIEW'
    ]),
    goTo(url) {
      this.$router.push(url);
      this.SET_IS_PAYMENT_COMPLETED(false);
      this.SET_IS_ORDER_PREVIEW(false);
      this.CLEAR_SELECTED_REGIONS();
    }
  }
};
</script>
