<template>
  <div
    class="regions-dropdown-wrapper"
    :class="{ 'dropdown-open': isOpen }"
    ref="dropdown"
    v-if="!isUserUsingTrialSubscription"
  >
    <div class="dropdown-menu-toggle-wrapper">
      <button class="dropdown-menu-toggle-button" @click="isOpen = !isOpen">
        <icon-add class="dropdown-menu-toggle-button__icon" />
        Update markets
      </button>
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-header">
        <div class="dropdown-input-container">
          <icon-search class="dropdown-input-container__icon" />
          <input
            type="text"
            class="dropdown-input-container__input"
            placeholder="Search country"
            v-model="filterInputValue"
          />
        </div>

        <k-button theme-color="primary" @click="handleDoneClick">
          Done
        </k-button>
      </div>

      <div class="dropdown-content">
        <ul class="dropdown-items">
          <template v-for="item in filteredItems">
            <template v-if="item.countries && item.countries.length">
              <li :key="item.region" class="dropdown-region-title">
                {{ item.region }}
              </li>

              <li
                v-for="country in item.countries"
                :key="country.id"
                :class="{
                  selected: country.selected,
                  'dropdown-item': !country.disabled
                }"
                @click.prevent="selectCountry(country)"
              >
                <k-tooltip
                  v-if="country.disabled"
                  class="dropdown-item"
                  :title="'You\'ve purchased this product at a special price <br/> as part of your subscription.<br/> Once selected, this product cannot be deselected'"
                  :position="'top'"
                >
                  <input
                    type="checkbox"
                    v-model="country.selected"
                    class="k-checkbox k-checkbox-md k-rounded-md"
                    :class="{ 'k-checked': country.selected }"
                    @click.stop
                    :disabled="isCheckboxDisabled(country)"
                  />

                  <span class="dropdown-item__text">{{ country.text }}</span>
                  <span class="dropdown-item__price">
                    {{ country.price }}
                  </span>
                </k-tooltip>

                <template v-else>
                  <input
                    type="checkbox"
                    v-model="country.selected"
                    class="k-checkbox k-checkbox-md k-rounded-md"
                    :class="{ 'k-checked': country.selected }"
                    @click.stop
                    :disabled="isCheckboxDisabled(country)"
                  />

                  <span class="dropdown-item__text">{{ country.text }}</span>
                  <span class="dropdown-item__price">
                    {{ country.price }}
                  </span>
                </template>
              </li>
            </template>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import userSubscriptionMixin from '@/components/tenantAdmin/mixins/userSubscription';
import updateOrderMixin from './mixins/updateOrder';

export default {
  components: {},
  mixins: [userSubscriptionMixin, updateOrderMixin],
  inject: ['getEstimatedSubscription'],
  data() {
    return {
      isOpen: false,
      filterInputValue: '',
      allItems: []
    };
  },
  computed: {
    ...mapGetters('fastspring', [
      'showPaymentForm',
      'isSubscribed',
      'selectedBundle',
      'dropdownRegions',
      'subscription'
    ]),
    selectedItems() {
      return this.allItems
        .flatMap((t) => t.countries)
        .filter((t) => t.selected);
    },
    isUserSelectedAnyRegions() {
      const userSelectedRegion = this.subscription.regionProductIds;
      const formattedUserSelectedRegion =
        userSelectedRegion && userSelectedRegion.length > 0
          ? JSON.parse(userSelectedRegion)
          : [];

      return formattedUserSelectedRegion.length > 0;
    },
    filteredItems() {
      return this.allItems.map((item) => ({
        region: item.region,
        countries: item.countries.filter((t) =>
          t.text
            .toLowerCase()
            .includes(this.filterInputValue.toLowerCase().trim())
        )
      }));
    }
  },
  created() {
    this.addWindowClickEventListener();
    this.allItems = JSON.parse(JSON.stringify(this.dropdownRegions));

    if (this.isUserUsingTrialSubscription && !this.isUserSelectedAnyRegions) {
      this.assignRandomCountryToUser();
    }
  },
  methods: {
    ...mapMutations('fastspring', ['SET_SELECTED_REGIONS']),
    ...mapActions('fastspring', ['updateCart']),

    addWindowClickEventListener() {
      window.addEventListener('click', (e) => {
        if (!this.isRegionDropdownElement(e.target)) {
          this.isOpen = false;
        }
      });
    },
    isRegionDropdownElement(e) {
      if (
        !e.parentNode ||
        e.parentNode.toString() === '[object HTMLDocument]'
      ) {
        return false;
      }

      if (e.parentNode === this.$refs.dropdown) {
        return true;
      }

      return this.isRegionDropdownElement(e.parentNode);
    },
    selectCountry(item) {
      if (this.isCheckboxDisabled(item)) {
        return;
      }

      item.selected = !item.selected;
    },
    isCheckboxDisabled(item) {
      if (item.disabled) return true;

      //User is choosing their first countries list when create their subscription.
      //We only allow them too choose maximum number of countries based on their subscription bundle's allowance.
      return (
        !this.subscription.fastspringSubscriptionId &&
        !item.selected &&
        this.selectedBundle.regionCount === this.selectedItems.length
      );
    },
    assignRandomCountryToUser() {
      const allCountries = this.allItems.flatMap((t) => t.countries).slice();

      const randomCountry =
        allCountries[Math.floor(Math.random() * allCountries.length)];

      this.selectCountry(randomCountry);
      this.handleDoneClick();
    },
    async handleDoneClick() {
      this.updateSelectedItems();
      this.isOpen = false;

      if (
        this.isSubscribed ||
        this.selectedBundle.regionCount === this.selectedItems.length
      ) {
        try {
          const cart = await this.updateCart();
          const estimatedSubscription = await this.updateOrder(cart);

          this.getEstimatedSubscription(estimatedSubscription);
        } catch (error) {
          console.error('Update Cart Error:', error);
        }
      }
    },
    updateSelectedItems() {
      const countries = this.dropdownRegions.flatMap((t) => t.countries);

      this.allItems
        .flatMap((t) => t.countries)
        .forEach((item) => {
          countries.find((t) => t.id === item.id).selected = item.selected;
        });

      this.SET_SELECTED_REGIONS(this.selectedItems);
    }
  }
};
</script>

<style lang="scss" scoped>
.regions-dropdown-wrapper {
  height: 48px;
}

.dropdown-menu-toggle-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 18px;
  background-color: var(--primary);
  color: var(--button-text);
}

.dropdown-menu-toggle-button {
  background: transparent;
  color: var(--button-text);
  border: none;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;

  &__icon {
    margin-right: 12px;
  }
}

.dropdown-menu {
  width: 644px;
  margin-top: 8px;
  padding: 0;
  position: absolute;
  display: none;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.dropdown-open .dropdown-menu {
  display: block;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.dropdown-input-container {
  padding: 14px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  height: 44px;
  width: 306px;
  font-size: 16px;
  font-weight: 400;

  &__icon {
    color: var(--body-text);
    margin-right: 16px;
  }

  &__input {
    width: 100%;
    line-height: 30px;
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
    color: var(--body-text);
  }
}

.dropdown-content {
  max-height: 270px;
  overflow-y: auto;
  margin: 10px 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 2238px;
    font-size: 16px;
    line-height: 19px;

    .dropdown-region-title {
      padding: 8px 16px;
      height: 32px;
      color: #4e5b6c;
    }

    .dropdown-item {
      padding: 12px 16px;
      color: var(--body-text);
      height: 42px;
      width: 312px;
      cursor: pointer;
      display: flex;
      align-items: center;
      overflow: hidden;

      &.selected {
        color: #464e5f;
      }

      &:hover {
        background: rgba(78, 91, 108, 0.425);
      }

      &__image {
        width: 26.6px;
        height: 20px;

        &.default {
          background-color: #eee;
        }
      }

      &__text {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 10px;
      }

      &__price {
        color: #4e5b6c;
      }
    }
  }
}
</style>
