<template>
  <div class="form-container">
    <div class="form-container-header">
      <div class="form-container-header-title">
        <span class="header-icon">
          <slot name="header-icon" />
        </span>
        {{ title }}
      </div>
    </div>

    <div class="form-container-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.form-container {
  background: var(--component-bg);
  display: flex;
  flex-direction: column;
}

.form-container-header {
  background: var(--primary);
  color: var(--button-text);
  padding: 16px;
}

.form-container-header-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.header-icon {
  margin-right: 8px;
}

.form-container-body {
  flex: 1;
  min-height: 730px;
  position: relative;
}
</style>
