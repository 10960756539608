<template>
  <div class="button-container">
    <button class="fade-in" @click="accessDashboard">
      <div>
        <img src="/chart.png" :width="60" :height="60" alt="" />
        <br />
        Access <br />Dashboard
      </div>
    </button>
    <button class="fade-in" @click="accessLuca">
      <div>
        <img src="/favicon.ico" :width="60" :height="60" alt="" />
        <br />Access <br />Luca
      </div>
    </button>
  </div>
</template>

<script>
import { getUrl } from '@/shared/utils/luca';
import loginRedirectMixin from '@/shared/mixins/loginRedirect';

export default {
  name: 'AccessComponent',
  mixins: [loginRedirectMixin],
  methods: {
    accessDashboard() {
      this.redirectToDashboard();
    },
    accessLuca() {
      window.location.href = getUrl('luca');
    }
  }
};
</script>

<style scoped lang="scss">
@property --gradient-angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  button {
    padding: 0;
    margin: 0 40px;
    color: var(--body-text);
    border-radius: 16px;
    font-size: 20px;
    line-height: 24px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: conic-gradient(
        from var(--gradient-angle),
        var(--secondary),
        var(--primary),
        var(--primary),
        var(--secondary)
      );
      border-radius: inherit;
      animation: rotation 10s linear infinite;
    }

    &::after {
      filter: blur(10px);
    }

    &:hover {
      &::after {
        filter: blur(16px);
      }
    }

    img {
      margin-bottom: 8px;
    }

    div {
      background-color: var(--primary);
      border-radius: 14px;
      padding: 20px 40px;
      margin: 2px;
      width: 100%;
      text-align: center;
      position: relative;
      z-index: 2;
      color: var(--button-text);
    }
  }
}

.fade-in {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}
</style>
