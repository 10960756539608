<template>
  <div class="manage-users">
    <manage-users-header
      :remaining-seat-count="remainingSeatCount"
      @openSubscriptionDetailDialog="openSubscriptionDetailDialog"
    />

    <div class="body container">
      <div class="tenant-admin-table">
        <div class="tenant-admin-table-header">
          <div class="tenant-admin-table-title">Manage Users</div>
          <div class="tenant-admin-table-actions">
            <k-button theme-color="primary" @click="addUser">
              Add User
            </k-button>
          </div>
        </div>

        <div class="tenant-admin-table-content">
          <k-grid
            :data-items="dataResult"
            :pageable="pageable"
            :sortable="sortable"
            :sort="sort"
            :skip="skip"
            :take="take"
            :columns="columns"
            scrollable="none"
          >
            <template v-slot:statusTemplate="{ props }">
              <td>
                <div
                  v-if="props.dataItem.disabled"
                  class="status-text inactive"
                >
                  Inactive
                </div>
                <div v-else class="status-text active">Active</div>
              </td>
            </template>

            <template v-slot:actionTemplate="{ props }">
              <td>
                <div class="d-flex flex-row justify-content-center">
                  <k-button
                    class="custom-button"
                    theme-color="secondary"
                    fill-mode="solid"
                    rounded="full"
                    @click="showEditDialog(props.dataItem)"
                  >
                    <icon-edit />
                  </k-button>

                  <k-button
                    v-if="props.dataItem.uid !== principal.uid"
                    class="custom-button ms-3"
                    theme-color="secondary"
                    fill-mode="solid"
                    rounded="full"
                    @click="showDeleteConfirmationDialog(props.dataItem)"
                  >
                    <icon-trash />
                  </k-button>
                </div>
              </td>
            </template>
          </k-grid>
        </div>
      </div>
    </div>

    <edit-user-dialog
      v-if="editUserDialog.show"
      :show.sync="editUserDialog.show"
      :title="editUserDialog.title"
      :user="entity"
      @click-save="onEditSave"
    />

    <k-dialog
      v-if="deleteConfirmationDialog.show"
      :title="' '"
      @close="closeDeleteConfirmationDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            Are you sure you want to delete
            {{ deleteConfirmationDialog.dataItem.displayName }}?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeDeleteConfirmationDialog"
          >
            Close
          </k-button>

          <k-button
            theme-color="primary"
            @click="deleteUser(deleteConfirmationDialog.dataItem)"
          >
            Confirm
          </k-button>
        </div>
      </div>
    </k-dialog>

    <k-dialog
      v-if="cancelSubscriptionNoticeDialog.show"
      :title="' '"
      @close="closeCancelSubscriptionNoticeDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            <template v-if="!cancelSubscriptionNoticeDialog.paymentOverdue">
              Your subscription will be active until
              {{ subscription.nextChargeDate }}. You can always resume your
              subscription until {{ subscription.nextChargeDate }}
            </template>
            <template v-else>
              Your subscription has been canceled from
              {{ subscription.nextChargeDate }}. You can always contact us to
              resume your subscription
            </template>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeCancelSubscriptionNoticeDialog"
          >
            Close
          </k-button>
        </div>
      </div>
    </k-dialog>

    <k-dialog
      v-if="trialSubscriptionEndNoticeDialog.show"
      :title="' '"
      @close="closeTrialSubscriptionEndNoticeDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            Your trial subscription will end soon.
            <br />
            You can visit
            <router-link to="/subscriptionPlans">this link</router-link> to
            upgrade your subscription
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeTrialSubscriptionEndNoticeDialog"
          >
            Close
          </k-button>
        </div>
      </div>
    </k-dialog>

    <k-dialog
      v-if="paymentOverdueNoticeDialog.show"
      :title="' '"
      @close="paymentOverdueNoticeDialog.show = false"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            <template>
              Your subscription has been stopped due to overdue payment. Please
              make any necessary payment before proceeding to use our
              application
            </template>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="paymentOverdueNoticeDialog.show = false"
          >
            Close
          </k-button>
        </div>
      </div>
    </k-dialog>

    <subscription-detail-dialog ref="subscriptionDetailDialog" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { process } from '@progress/kendo-data-query';
import ManageUsersHeader from '@/components/tenantAdmin/manageUsersHeader.vue';
import EditUserDialog from '@/components/tenantAdmin/editUserDialog.vue';
import SubscriptionDetailDialog from '@/components/tenantAdmin/components/SubscriptionDetailDialog/SubscriptionDetailDialog.vue';
import getUserSubscriptionMixin from './mixins/getUserSubscription';
import userSubscriptionMixin from './mixins/userSubscription';
import SLBMixin from '@/shared/mixins/slb';

export default {
  name: 'manageUsers',
  mixins: [getUserSubscriptionMixin, SLBMixin, userSubscriptionMixin],
  components: {
    EditUserDialog,
    ManageUsersHeader,
    SubscriptionDetailDialog
  },
  data() {
    return {
      pageable: false,
      sortable: true,
      skip: 0,
      take: 10,
      sort: [{ field: 'companyId', dir: 'asc' }],
      filter: null,
      columns: [
        { field: 'displayName', title: 'NAME' },
        { field: 'email', title: 'EMAIL' },
        { field: '', title: 'STATUS', cell: 'statusTemplate' },
        { field: '', title: 'ACTIONS', width: 90, cell: 'actionTemplate' },
        { field: '', title: '', width: 40 }
      ],
      dataResult: [],
      entityList: [],

      editUserDialog: {
        show: false,
        title: ' '
      },
      deleteConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      cancelSubscriptionNoticeDialog: {
        show: false,
        paymentOverdue: false
      },
      trialSubscriptionEndNoticeDialog: {
        show: false
      },
      paymentOverdueNoticeDialog: {
        show: false
      },
      entity: {},
      isInitialized: false
    };
  },
  computed: {
    ...mapGetters(['principal']),
    ...mapGetters('fastspring', [
      'subscription',
      'cancelSubscriptionNoticeable',
      'trialSubscriptionEndNoticeable',
      'isCancelingSubscription'
    ]),
    remainingSeatCount() {
      if (!this.subscription) {
        return 0;
      }

      const count = this.subscription.seatCount - this.entityList.length;
      return count < 0 ? 0 : count;
    }
  },
  async mounted() {
    await this.getSubscriptionInfo();
    this.getUserList();
    this.checkForSubscriptionState();
  },
  methods: {
    ...mapMutations(['showNotification']),
    ...mapMutations('fastspring', [
      'CANCEL_SUBSCRIPTION_NOTICEABLE',
      'TRIAL_SUBSCRIPTION_END_NOTICEABLE'
    ]),
    ...mapActions('fastspring', ['getAccountListOfUser']),

    onEditSave(updatedEntity) {
      updatedEntity.tenantId = this.principal.tenantId;

      axios.post('/person/edit', updatedEntity).then((response) => {
        if (response.status === 200) {
          this.getUserList();
        }
      });
    },

    async getUserList() {
      this.entityList = await this.getAccountListOfUser();

      const dataState = {
        skip: this.skip,
        take: this.take,
        sort: this.sort
      };
      this.dataResult = process(this.entityList, dataState);
    },
    addUser() {
      const userCount = this.entityList.length;

      if (userCount < this.subscription.seatCount) {
        this.$router.push('/createUser');
      } else {
        const content =
          this.subscription.seatCount > 0
            ? `You don't have enough seats. Please update your subscription to add more users.`
            : `You haven't subscribed to any bundle yet. Please update your subscription first.`;

        const notification = { title: '', content };
        this.showNotification(notification);
      }
    },
    deleteUser(dataItem) {
      if (dataItem.tenantId === this.principal.tenantId) {
        return;
      }

      dataItem.tenantId = this.principal.tenantId;
      axios.post('/person/delete', dataItem).then((response) => {
        if (response.status === 200) {
          this.getUserList();
          this.closeDeleteConfirmationDialog();
        }
      });
    },

    async checkForSubscriptionState() {
      this.cancelSubscriptionNoticeDialog.paymentOverdue =
        this.isPaymentOverdue;

      if (this.isCancelingSubscription && this.cancelSubscriptionNoticeable) {
        this.cancelSubscriptionNoticeDialog.show = true;
      }

      if (this.isUserUsingTrialSubscription) {
        if (
          this.isTrialSubscriptionAboutToEnd &&
          this.trialSubscriptionEndNoticeable
        ) {
          this.trialSubscriptionEndNoticeDialog.show = true;
        }
      }

      if (this.isPaymentOverdue) {
        this.paymentOverdueNoticeDialog.show = true;
      }
    },
    showEditDialog(dataItem) {
      this.entity = this.$root.clone(dataItem);
      this.editUserDialog.title = 'Edit User ' + dataItem.displayName;
      this.editUserDialog.show = true;
    },
    showDeleteConfirmationDialog(dataItem) {
      this.deleteConfirmationDialog.dataItem = dataItem;
      this.deleteConfirmationDialog.show = true;
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },
    closeCancelSubscriptionNoticeDialog() {
      this.cancelSubscriptionNoticeDialog.show = false;
      this.CANCEL_SUBSCRIPTION_NOTICEABLE(false);
    },
    closeTrialSubscriptionEndNoticeDialog() {
      this.trialSubscriptionEndNoticeDialog.show = false;
      this.TRIAL_SUBSCRIPTION_END_NOTICEABLE(false);
    },
    openSubscriptionDetailDialog() {
      this.$refs.subscriptionDetailDialog.openSubscriptionDetailDialog();
    }
  }
};
</script>
