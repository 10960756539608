<template>
  <div class="manage-users">
    <manage-users-header />

    <div class="body container">
      <div class="tenant-admin-table">
        <div class="tenant-admin-table-header">
          <div class="col-4 d-flex align-items-center">
            <k-button
              class="unset-min-width k-button-sm k-rounded-md k-icon-button me-2"
              theme-color="secondary"
              fill-mode="outline"
              @click="$router.go(-1)"
            >
              <span class="k-icon k-i-arrow-chevron-left"></span>
            </k-button>
            <span>Manage Users</span>
          </div>

          <div class="tenant-admin-table-actions">
            Remaining Seats: {{ remainingSeatCount }}
            <k-button theme-color="primary" @click="addUser" class="ms-2">
              Add User
            </k-button>
          </div>
        </div>

        <div class="tenant-admin-table-content">
          <k-grid
            :data-items="dataResult"
            :pageable="pageable"
            :sortable="sortable"
            :sort="sort"
            :skip="skip"
            :take="take"
            :columns="columns"
            @datastatechange="dataStateChange"
            @rowclick="rowClick"
            scrollable="none"
          >
            <template v-slot:statusTemplate="{ props }">
              <td
                :class="
                  !props.dataItem.disabled ? 'k-text-success' : 'k-text-error'
                "
              >
                {{ !props.dataItem.disabled ? 'Active' : 'Disabled' }}
              </td>
            </template>

            <template v-slot:actionTemplate="{ props }">
              <td>
                <actions-button>
                  <k-button
                    theme-color="secondary"
                    fill-mode="solid"
                    class="mx-2 my-1 is-small"
                    @click="showResetPasswordConfirmation(props.dataItem)"
                  >
                    Reset Password
                  </k-button>
                  <k-button
                    theme-color="secondary"
                    fill-mode="solid"
                    class="mx-2 my-1 is-small"
                    @click="showEditStatusDialog(props.dataItem)"
                  >
                    Edit Status
                  </k-button>
                  <k-button
                    theme-color="secondary"
                    fill-mode="solid"
                    class="mx-2 my-1 is-small"
                    @click="showEditDialog(props.dataItem)"
                    ><icon-edit />
                  </k-button>
                  <k-button
                    theme-color="secondary"
                    fill-mode="solid"
                    class="mx-2 my-1 is-small"
                    @click="showDeleteConfirmationDialog(props.dataItem)"
                    v-if="uid !== props.dataItem.uid"
                    ><icon-trash
                  /></k-button>
                </actions-button>
              </td>
            </template>
          </k-grid>
        </div>
      </div>
    </div>

    <k-dialog v-if="dialog.show" :title="' '" @close="closeDialog">
      <div class="row mb-3">
        <div class="col-12 text-center" v-html="dialog.title"></div>
      </div>
      <form @submit.prevent="save">
        <div class="row" v-if="entity.uid && !entity.isStatus">
          <div class="col-12">
            User ID
            <k-inputs v-model="entity.uid" name="uid" :disabled="true" />
          </div>
        </div>
        <div class="row" v-if="!entity.isStatus">
          <div class="col-12">
            User Name
            <k-inputs
              v-model="entity.displayName"
              name="displayName"
              :required="true"
              placeholder="Eg. John Doe"
            />
          </div>
        </div>
        <div class="row" v-if="!entity.isStatus">
          <div class="col-12">
            Email
            <k-inputs
              v-model="entity.email"
              name="email"
              :required="true"
              placeholder="Eg. john.doe@mail.com"
              :pattern="'^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+([a-zA-Z]){2,7}$'"
            />
          </div>
        </div>
        <div class="row" v-if="entity.isStatus">
          <div class="col-12 mb-3">
            <k-dropdown-native
              :data-items="status"
              :text-field="'text'"
              :data_item-key="'id'"
              :defaultValue="entity.status"
              @change="changeStatus"
            >
            </k-dropdown-native>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <confirm-popup
      :isShowing="deleteConfirmationDialog.show"
      @onClose="closeDeleteConfirmationDialog"
      @onConfirm="deleteUser(deleteConfirmationDialog.dataItem)"
    >
      <p class="fw-bold text-center">
        Are you sure you want to delete
        {{ deleteConfirmationDialog.dataItem.displayName }}?
      </p>
    </confirm-popup>

    <confirm-popup
      :isShowing="resetPasswordConfirmationDialog.show"
      @onClose="closeResetPasswordConfirmation"
      @onConfirm="sendLinkResetPassword"
    >
      <p class="fw-bold text-center">
        Are you sure you want to send a password reset link to
        {{ resetPasswordConfirmationDialog.dataItem.email }}?
      </p>
    </confirm-popup>

    <confirm-popup
      :isShowing="notificationDialog.show"
      @onClose="notificationDialog.show = false"
      :hideConfirmButton="true"
    >
      <p class="fw-bold" v-html="notificationDialog.title"></p>
      <p v-html="notificationDialog.message"></p>
    </confirm-popup>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import { process } from '@progress/kendo-data-query';
import ManageUsersHeader from './manageTenantUsersHeader.vue';
import { userFormValidator } from '@/plugins/validator';
import ConfirmPopup from '@/components/admin/components/confirmPopup.vue';
import ActionsButton from '@/components/admin/components/actionsButton.vue';

export default {
  name: 'yourTenants',
  components: {
    ManageUsersHeader,
    ConfirmPopup,
    ActionsButton
  },
  data() {
    return {
      pageable: true,
      sortable: true,
      skip: 0,
      take: 10,
      sort: [{ field: 'uid', dir: 'asc' }],
      filter: null,
      columns: [
        { field: 'uid', title: 'User ID' },
        { field: 'displayName', title: 'User Name' },
        { field: 'email', title: 'Contact Email' },
        {
          field: 'disabled',
          title: 'Status',
          width: 100,
          cell: 'statusTemplate'
        },
        { field: '', title: 'Actions', cell: 'actionTemplate', width: 100 }
      ],
      personList: null,
      editUserDialog: {
        show: false,
        title: ' '
      },
      deleteConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      dialog: {
        show: false,
        title: null
      },
      entity: {
        uid: '',
        displayName: '',
        disabled: false,
        email: '',
        status: { id: null, text: '' },
        isStatus: null
      },
      gridClickedRow: {},
      status: [
        { id: true, text: 'Active' },
        { id: false, text: 'Disabled' }
      ],
      resetPasswordConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      notificationDialog: {
        show: false,
        title: null,
        message: null
      },
      userFormValidator
    };
  },
  computed: {
    ...mapGetters(['principal']),

    listUser() {
      return this.personList && this.personList.listUser
        ? this.personList.listUser
        : [];
    },
    userLimit() {
      return this.personList && this.personList.limit
        ? this.personList.limit
        : 0;
    },
    remainingSeatCount() {
      const count = this.userLimit - this.listUser.length;
      return count < 0 ? 0 : count;
    },
    uid() {
      return this.principal.uid;
    },
    dataResult() {
      return process(this.listUser, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter
      });
    },
    tenantId() {
      return this.principal.tenantId;
    }
  },
  async mounted() {
    this.userList();
  },
  methods: {
    ...mapMutations(['showNotification']),

    changeStatus(event) {
      this.entity.status = event.value;
    },
    async userList() {
      try {
        const response = await axios.get('/person/list-user-manage', {
          tenantId: this.tenantId
        });

        this.personList = response.data;
      } catch (e) {
        this.$router.go(-1);
      }
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
    },
    rowClick(event) {
      this.windowVisible = true;
      this.gridClickedRow = event.dataItem;
    },
    showDialog() {
      this.dialog.title = 'Add User to ' + this.tenantId;
      this.dialog.show = true;
    },
    showEditDialog(dataItem) {
      this.dialog.title = 'Edit User ' + dataItem.displayName;
      this.entity = this.$root.clone(dataItem);
      this.entity.isStatus = false;
      this.dialog.show = true;
    },
    showEditStatusDialog(dataItem) {
      this.tStatus = { id: true, text: 'Active' };
      if (dataItem.disabled) {
        this.tStatus = { id: false, text: 'Disabled' };
      }
      this.dialog.title = 'Edit ' + dataItem.displayName + ' Status';
      this.entity = this.$root.clone(dataItem);
      this.entity.status = this.status.find((x) => x.id == !dataItem.disabled);
      this.dialog.show = true;
      this.entity.isStatus = true;
    },
    closeDialog() {
      this.dialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
    },
    async save() {
      let formData = this.$root.clone(this.entity);
      if (formData.status) {
        formData.disabled = !formData.status.id;
      }
      formData.tenantId = this.tenantId;
      let url = '/person/add';
      if (this.entity.uid) {
        url = '/person/edit';
      }
      const response = await axios.post(url, formData);

      if (response.status == 200) {
        this.closeDialog();
        this.userList();
        if (response.data.password && url === '/person/add') {
          this.notificationDialog.message = `<p>We have sent this user an email with instructions on how to setup their password. Please inform them to kindly click the link provided in the email to proceed.
            <span style="color: red;">Please keep in mind that that link will expire in 3 days.</span>  <br/><br/> If they haven't received our email, please tell them to check their Spam or Junk folder and mark us as 'Not Spam' to ensure future communications reach your inbox.</p>`;
          this.notificationDialog.show = true;
        }
      }
    },
    showDeleteConfirmationDialog(dataItem) {
      this.deleteConfirmationDialog.dataItem = dataItem;
      this.deleteConfirmationDialog.show = true;
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },
    deleteUser(dataItem) {
      dataItem.tenantId = this.tenantId;
      axios.post('/person/delete', dataItem).then((response) => {
        if (response.status == 200) {
          this.userList();
          this.closeDeleteConfirmationDialog();
        }
      });
    },
    showResetPasswordConfirmation(dataItem) {
      this.resetPasswordConfirmationDialog.dataItem = dataItem;
      this.resetPasswordConfirmationDialog.show = true;
    },
    closeResetPasswordConfirmation() {
      this.resetPasswordConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },
    sendLinkResetPassword() {
      axios
        .post('/auth/forgotPassword', {
          email: this.resetPasswordConfirmationDialog.dataItem.email
        })
        .then((response) => {
          if (response.status == 200) {
            this.closeResetPasswordConfirmation();
          }
        });
    },
    addUser() {
      const userCount = this.listUser.length;

      if (userCount < this.userLimit) {
        this.showDialog();
      } else {
        const content = `You don't have enough seats. Please contact our team to add more seats`;

        const notification = { title: '', content };
        this.showNotification(notification);
      }
    }
  }
};
</script>
