<template>
  <div class="create-user">
    <div class="form-container">
      <div class="form-title">Create User</div>

      <form @submit="save">
        <div class="field">
          <div class="label">Name</div>
          <k-inputs
            v-model="entity.displayName"
            name="name"
            :required="true"
            placeholder="Eg. John Doe"
          />
        </div>

        <div class="field">
          <div class="label">Email</div>
          <k-inputs
            v-model="entity.email"
            name="email"
            :required="true"
            type="email"
            placeholder="Eg. john-doe@mail.com"
          />
        </div>

        <div class="field">
          <k-button class="submit-btn" type="submit" theme-color="primary"
            >Submit</k-button
          >
        </div>
        <div class="field">
          <k-button
            class="cancel-btn"
            theme-color="secondary"
            fill-mode="outline"
            @click="handleCancelClick"
            >Cancel</k-button
          >
        </div>
      </form>
    </div>

    <k-dialog
      v-if="notificationDialog.show"
      :title="' '"
      @close="notificationDialog.show = false"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold" v-html="notificationDialog.title"></p>
          <p v-html="notificationDialog.message"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button class="me-2" fill-mode="outline" @click="closeDialog"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>

    <div v-show="isLoading" class="loader">
      <div class="k-overlay"></div>
      <img src="@/assets/img/loader.svg" alt="" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  components: {},
  inject: {
    kendoForm: { default: {} }
  },
  data() {
    return {
      entity: {
        map: {}
      },
      notificationDialog: {
        show: false,
        title: null,
        message: null
      },
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(['principal'])
  },
  methods: {
    save(event) {
      event.preventDefault();

      this.isLoading = true;

      this.entity.tenantId = this.principal.tenantId;

      axios
        .post('/person/add', this.entity)
        .then((response) => {
          if (response.status === 200) {
            this.notificationDialog.message = `<p>We have sent you an email with instructions on how to setup your password. Kindly click the link provided in the email to proceed.
            <span style="color: red;">Please keep in mind that that link will expire in 3 days.</span>  <br/><br/> If you haven't received our email, please check your Spam or Junk folder and mark us as 'Not Spam' to ensure future communications reach your inbox.</p>`;
            this.notificationDialog.show = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.$router.push('/manageUsers');
    },
    closeDialog() {
      this.notificationDialog.show = false;
      this.$router.push('/manageUsers');
    }
  }
};
</script>

<style lang="scss" scoped>
.create-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.form-container {
  width: 100%;
  max-width: 485px;
  background: var(--component-bg);
  border-radius: 8px;
  padding: 24px;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--body-text);
}

.form-title {
  margin-bottom: 42px;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}

.label {
  margin-bottom: 10px;
}

::v-deep .k-input {
  height: 44px;
  margin-bottom: 24px;
  background: var(--component-bg);
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text);
}

.submit-btn {
  height: 55px;
  width: 100%;
  margin-top: 12px;
  background: var(--primary);
  color: var(--button-text);
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

.cancel-btn {
  height: 55px;
  width: 100%;
  margin-top: 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  border-color: #e6e6e6;
  color: #e6e6e6;
}
</style>
