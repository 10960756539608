<template>
  <k-dialog
    v-if="subscriptionDetailDialog.dialogOpen"
    :title="' '"
    @close="closeSubscriptionDetailDialog"
    :class="[
      'k-dialog-wrapper--large',
      {
        'd-none': !subscriptionDetailDialog.contentVisible
      }
    ]"
  >
    <subscription-detail
      @onLoadDataCompleted="subscriptionDetailDialog.contentVisible = true"
    />

    <div class="row">
      <div class="col-12 text-center mt-2">
        <k-button
          class="me-2"
          fill-mode="outline"
          @click="closeSubscriptionDetailDialog"
        >
          Close
        </k-button>
      </div>
    </div>
  </k-dialog>
</template>

<script>
import SubscriptionDetail from '@/components/tenantAdmin/subscriptionDetail.vue';

export default {
  components: {
    SubscriptionDetail
  },
  data() {
    return {
      subscriptionDetailDialog: {
        dialogOpen: false,
        contentVisible: false
      }
    };
  },
  computed: {
    isAgreedTerms() {
      return this.subscription && this.subscription.agreedTerms;
    }
  },
  methods: {
    openSubscriptionDetailDialog() {
      if (this.subscriptionDetailDialog.dialogOpen === true) {
        this.subscriptionDetailDialog.contentVisible = true;
      }
      this.subscriptionDetailDialog.dialogOpen = true;
    },
    closeSubscriptionDetailDialog() {
      this.subscriptionDetailDialog.contentVisible = false;
    }
  }
};
</script>
