var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.disableUserDialog.dialogOpen)?_c('k-dialog',{class:[
      'k-dialog-wrapper--large',
      {
        'd-none': !_vm.disableUserDialog.contentVisible
      }
    ],attrs:{"title":' '},on:{"close":_vm.closeDisableUserDialog}},[_c('div',{staticClass:"manage-users"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 text-center title"},[_vm._v("Select users to disable")])]),_c('div',{staticClass:"body container"},[_vm._v(" "+_vm._s(_vm.tooltip)+" "),_c('div',{staticClass:"tenant-admin-table mt-2"},[_c('div',{staticClass:"tenant-admin-table-content"},[_c('k-grid',{attrs:{"data-items":_vm.dataResult,"pageable":_vm.pageable,"sortable":_vm.sortable,"sort":_vm.sort,"skip":_vm.skip,"take":_vm.take,"columns":_vm.columns,"scrollable":"none"},scopedSlots:_vm._u([{key:"statusTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',[(props.dataItem.disabled)?_c('div',{staticClass:"status-text inactive"},[_vm._v(" Inactive ")]):_c('div',{staticClass:"status-text active"},[_vm._v("Active")])])]}},{key:"actionTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',[_c('div',{staticClass:"checkbox-wrapper",on:{"click":function($event){return _vm.selectUser(props.dataItem)}}},[(props.dataItem.uid !== _vm.principal.uid)?_c('k-checkbox',{attrs:{"theme-color":"secondary","fill-mode":"solid","checked":_vm.disabledList.includes(props.dataItem.uid)}}):_vm._e()],1)])]}}],null,false,267250805)})],1)])]),_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-12 text-center mt-2"},[_c('k-button',{staticClass:"me-2",attrs:{"fill-mode":"outline"},on:{"click":_vm.closeDisableUserDialog}},[_vm._v(" Cancel ")]),_c('k-button',{attrs:{"theme-color":"primary"},on:{"click":_vm.showDisableConfirmationDialog}},[_vm._v(" Confirm ")])],1)])])]):_vm._e(),(_vm.disableConfirmationDialog.show)?_c('k-dialog',{attrs:{"title":' '},on:{"close":_vm.closeDisableConfirmationDialog}},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"fw-bold text-center"},[_vm._v(" Are you sure you want to disable those users ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center mt-2"},[_c('k-button',{staticClass:"me-2",attrs:{"fill-mode":"outline"},on:{"click":_vm.closeDisableConfirmationDialog}},[_vm._v(" Cancel ")]),_c('k-button',{attrs:{"theme-color":"primary"},on:{"click":_vm.disableUser}},[_vm._v(" Confirm ")])],1)])]):_vm._e(),_c('k-button',{attrs:{"theme-color":"primary"},on:{"click":_vm.openDisableUserDialog}},[_vm._v(" Disable Users "),_c('k-tooltip',{attrs:{"id":"tooltip","title":_vm.tooltip,"position":'left'}},[_c('span',{staticClass:"k-icon k-i-info"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }