import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters(['theme'])
  },
  methods: {
    ...mapMutations(['setTheme']),

    changeTheme(theme) {
      document.documentElement.setAttribute('data-theme', theme);
      this.setTheme(theme);
    },

    switchTheme() {
      if (this.theme === 'dark') {
        this.changeTheme('light');
      } else {
        this.changeTheme('dark');
      }
    },

    setupTheme() {
      if (this.theme) {
        document.documentElement.setAttribute('data-theme', this.theme);
        return;
      }

      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        this.changeTheme('dark');
      } else {
        this.changeTheme('light');
      }
    }
  }
};
