var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-form-element',[(
      _vm.kendoForm.visited &&
      _vm.kendoForm.touched &&
      (_vm.kendoForm.errors.newPassword || _vm.kendoForm.errors.confirmPassword)
    )?_c('div',{staticClass:"row col-12 error"},[_c('span',[_c('icon-warning')],1),(_vm.kendoForm.errors.newPassword && _vm.kendoForm.errors.confirmPassword)?_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.summary))}}):[_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.newPassword))}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.confirmPassword))}})]],2):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"mt-3"},[_vm._v(" We have provided a temporary passcode to ensure your details remain confidential. "),_c('span',{staticClass:"text-primary"},[_vm._v("Please create a new secure password.")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('k-field',{attrs:{"name":'newPassword',"type":'password',"component":'myTemplate',"placeholder":'Create Password'},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
    var props = ref.props;
return [_c('k-input',_vm._b({on:{"change":props.onChange,"blur":props.onBlur,"focus":props.onFocus}},'k-input',props,false))]}}])})],1),_c('div',{staticClass:"col-12"},[_c('k-field',{attrs:{"name":'confirmPassword',"type":'password',"component":'myTemplate',"placeholder":'Confirm Password'},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
    var props = ref.props;
return [_c('k-input',_vm._b({on:{"change":props.onChange,"blur":props.onBlur,"focus":props.onFocus}},'k-input',props,false))]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('k-button',{attrs:{"type":"submit","disabled":!_vm.kendoForm.allowSubmit,"theme-color":"primary"}},[_vm._v(" Create ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }