<template>
  <div class="layout layout--admin">
    <header class="layout__header">
      <div>
        <icon-logo-header />
      </div>
      <div class="header-icon">
        <span class="me-3"> <theme-toggle /> </span
        ><span>{{
          $store.state.principal.name
            ? $store.state.principal.name
            : $store.state.principal.email
        }}</span>
        <k-button
          class="custom-button"
          theme-color="secondary"
          fill-mode="link"
          rounded="full"
          @click="drawerVisible = true"
          ><icon-account
        /></k-button>
      </div>
    </header>
    <div
      class="right-drawer"
      :style="{
        width: drawerVisible ? '320px' : '0'
      }"
    >
      <div class="list-group">
        <button class="list-group-item mb-4 header">
          <div class="row justify-content-between">
            <div class="row col-10">
              <div class="col-3"><icon-account></icon-account></div>
              <div class="col-9 text-start px-0 text-truncate">
                <span v-text="$store.state.principal.name"></span>
              </div>
            </div>

            <div class="col-2 p-0">
              <a class="k-button-link" @click="drawerVisible = false"
                ><icon-close></icon-close
              ></a>
            </div>
          </div>
        </button>
        <button
          @click="userDialog = true"
          class="list-group-item list-group-item-action"
        >
          User Details
        </button>
        <button
          @click="updatePasswordDialog = true"
          class="list-group-item list-group-item-action"
        >
          Update Password
        </button>
        <button
          @click="logoutDialog = true"
          class="list-group-item list-group-item-action"
        >
          Logout
        </button>
      </div>
    </div>
    <!-- We will make the mask fill the screen while the menu is visible. Because its z-index is 1 less than that of the menu, the menu will still be displayed on top of it -->
    <div
      class="drawer-mask"
      :style="{
        width: drawerVisible ? '100vw' : '0',
        opacity: drawerVisible ? '0.6' : '0'
      }"
      @click="drawerVisible = false"
    ></div>
    <div class="layout__sidebar">
      <div class="list-group rounded-0">
        <router-link
          to="/administrator/tenant"
          class="list-group-item list-group-item-action"
          >Manage Tenants</router-link
        >
        <router-link
          to="/administrator/tenant-request"
          class="list-group-item list-group-item-action"
          >Tenants Requests</router-link
        >
        <router-link
          to="/administrator/user-request"
          class="list-group-item list-group-item-action"
          >Users Requests</router-link
        >
        <router-link
          to="/administrator/announcement"
          class="list-group-item list-group-item-action"
          >Announcements</router-link
        >
        <router-link
          to="/administrator/termandcondition"
          class="list-group-item list-group-item-action"
          >Terms & Conditions</router-link
        >
        <router-link
          to="/administrator/setting"
          class="list-group-item list-group-item-action"
          >Settings</router-link
        >
      </div>
    </div>
    <div class="layout__body">
      <div class="container--fluid">
        <router-view></router-view>
      </div>
    </div>
    <k-dialog v-if="userDialog" :title="' '" @close="userDialog = false">
      <div class="row">
        <div class="col-12">
          <div class="title d-flex justify-content-center">Your details</div>
          <div class="content py-3 d-flex justify-content-center">
            Contact your administrator to update your details
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 area">
          <div class="row">
            <div
              class="col-12 content d-flex justify-content-center"
              v-html="$store.state.principal.name"
            ></div>
          </div>
          <div class="row">
            <div
              class="col-12 content d-flex justify-content-center"
              v-html="$store.state.principal.email"
            ></div>
          </div>
          <div class="row">
            <div
              class="col-12 content d-flex justify-content-center"
              v-html="$store.state.principal.phone"
            ></div>
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-12 py-2 d-flex justify-content-center">
          <k-button class="me-2" fill-mode="outline" @click="userDialog = false"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="updatePasswordDialog"
      :title="' '"
      @close="updatePasswordDialog = false"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="title mb-2 d-flex justify-content-center">
            Update Password
          </p>
          <span class="content gray d-flex justify-content-center"
            >Send password reset link to registered email address</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="updatePasswordDialog = false"
            >Cancel</k-button
          >
          <k-button
            theme-color="primary"
            @click="sendLinkResetPassword($store.state.principal.email)"
            >Send</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog v-if="logoutDialog" :title="' '" @close="logoutDialog = false">
      <div class="row">
        <div class="col-12">
          <p class="title d-flex justify-content-center">
            Log out of Transparently.AI
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-2 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="logoutDialog = false"
            >Cancel</k-button
          >
          <k-button theme-color="primary" @click="logout">Finish</k-button>
        </div>
      </div>
    </k-dialog>
    <splash-screen
      v-if="$store.state.splashScreen"
      :isLoading="$store.state.splashScreen"
    />
    <v-idle class="d-none" @idle="logout" :loop="true" :duration="idleTime" />
  </div>
</template>

<script>
import axios from 'axios';
import { EventBus } from '../../main.js';
import ThemeToggle from '../themeToggle.vue';

export default {
  data() {
    return {
      drawerVisible: false,
      userDialog: false,
      updatePasswordDialog: false,
      logoutDialog: false,
      idleTime: 3600 * 12 // initialize idle time (12 hours)
    };
  },
  components: {
    ThemeToggle
  },
  mounted() {
    /* Splash Screen config - Begin*/
    setTimeout(() => {
      this.$store.state.splashScreen = false;
    }, 3000);
    /* Splash Screen config - End*/

    EventBus.$on('resetPassword', (email) => {
      this.sendLinkResetPassword(email);
    });

    this.getIdleTime();
  },
  destroyed() {
    EventBus.$off('resetPassword');
  },
  methods: {
    sendLinkResetPassword(email) {
      axios
        .post('/auth/forgotPassword', {
          email: email
        })
        .then((response) => {
          if (response.status == 200) {
            this.updatePasswordDialog = false;
            this.drawerVisible = false;
          }
        });
    },
    logout() {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push('/');
        })
        .catch(() => {});
    },
    getIdleTime() {
      axios.post('/setting/idleTime').then((response) => {
        this.idleTime = parseInt(response.data.value);
      });
    }
  }
};
</script>
