<template>
  <div class="regions-form">
    <div class="regions-form-title">Regions</div>
    <div class="body">
      <template v-if="isSubscribed && !isPaymentCompleted">
        <regions-dropdown v-if="isModifying" />

        <region-item
          v-for="region in selectedRegions"
          :key="region.id"
          :item="region"
        />
      </template>

      <template v-else>
        <regions-dropdown v-if="!isPaymentCompleted" />

        <template v-if="showPaymentForm">
          <region-item
            v-for="region in selectedRegions"
            :key="region.id"
            :item="region"
          />
        </template>
      </template>

      <div v-if="regionCountErrorMessage" class="error-message">
        {{ regionCountErrorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RegionsDropdown from '@/components/tenantAdmin/regionsDropdown.vue';
import RegionItem from '@/components/tenantAdmin/regionItem.vue';

export default {
  components: { RegionItem, RegionsDropdown },
  props: ['isModifying'],
  computed: {
    ...mapGetters('fastspring', [
      'selectedRegions',
      'isSubscribed',
      'showPaymentForm',
      'isPaymentCompleted',
      'selectedBundle'
    ]),

    isUserSelectedRegions() {
      return (
        this.selectedBundle &&
        this.selectedBundle.regionCount > 0 &&
        this.selectedRegions &&
        this.selectedRegions.length > 0
      );
    },
    isUserSelectedMoreThanAssignedRegionCount() {
      if (this.isUserSelectedRegions) {
        return this.selectedRegions.length > this.selectedBundle.regionCount;
      }
      return false;
    },
    isUserSelectedLessThanAssignedRegionCount() {
      if (this.isUserSelectedRegions) {
        return this.selectedRegions.length < this.selectedBundle.regionCount;
      }
      return false;
    },
    regionCountErrorMessage() {
      if (!this.isSubscribed) {
        if (this.isUserSelectedLessThanAssignedRegionCount)
          return `Please select ${
            this.selectedBundle.regionCount - this.selectedRegions.length
          } more region(s) in order to continue`;
      }

      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.regions-form {
  flex: 1;
  color: var(--body-text);

  display: flex;
  flex-direction: column;
}

.regions-form-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 16px;
}

.body {
  flex: 1;
  border-radius: 8px;
  border: 1px solid var(--border);
  overflow: hidden;
  background: var(--body-bg);
}

.error-message {
  color: var(--error);
  padding: 18px;
}
</style>
