<template>
  <k-form-element>
    <div
      v-if="
        kendoForm.visited &&
        kendoForm.touched &&
        (kendoForm.errors.newPassword || kendoForm.errors.confirmPassword)
      "
      class="row col-12 error"
    >
      <span><icon-warning /></span>
      <span
        v-if="kendoForm.errors.newPassword && kendoForm.errors.confirmPassword"
        v-text="$t(kendoForm.errors.summary)"
      ></span>
      <template v-else>
        <span v-text="$t(kendoForm.errors.newPassword)"></span>
        <span v-text="$t(kendoForm.errors.confirmPassword)"></span>
      </template>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <p class="mt-3">
          We have provided a temporary passcode to ensure your details remain
          confidential.
          <span class="text-primary">Please create a new secure password.</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <k-field
          :name="'newPassword'"
          :type="'password'"
          :component="'myTemplate'"
          :placeholder="'Create Password'"
        >
          <template v-slot:myTemplate="{ props }">
            <k-input
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
            />
          </template>
        </k-field>
      </div>
      <div class="col-12">
        <k-field
          :name="'confirmPassword'"
          :type="'password'"
          :component="'myTemplate'"
          :placeholder="'Confirm Password'"
        >
          <template v-slot:myTemplate="{ props }">
            <k-input
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
            />
          </template>
        </k-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <k-button
          type="submit"
          :disabled="!kendoForm.allowSubmit"
          theme-color="primary"
        >
          Create
        </k-button>
      </div>
    </div>
  </k-form-element>
</template>

<script>
export default {
  inject: {
    kendoForm: { default: {} }
  }
};
</script>
