<template>
  <div class="theme-toggle">
    <input
      type="checkbox"
      id="theme-toggle"
      class="hidden-checkbox"
      ref="themeToggleRef"
    />
    <label class="toggle-button" @click="toggleTheme">
      <div class="icon-wrapper">
        <svg
          class="icon sun"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="5"></circle>
          <line x1="12" y1="1" x2="12" y2="3"></line>
          <line x1="12" y1="21" x2="12" y2="23"></line>
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
          <line x1="1" y1="12" x2="3" y2="12"></line>
          <line x1="21" y1="12" x2="23" y2="12"></line>
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
        </svg>
        <svg
          class="icon moon"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
        </svg>
      </div>
    </label>
  </div>
</template>

<script>
import themeMixins from '@/shared/mixins/theme';

export default {
  name: 'themeToggle',
  mixins: [themeMixins],
  mounted() {
    if (this.theme === 'light') {
      this.$refs.themeToggleRef.checked = false;
    } else {
      this.$refs.themeToggleRef.checked = true;
    }
  },
  methods: {
    toggleTheme() {
      this.$refs.themeToggleRef.checked = !this.$refs.themeToggleRef.checked;
      this.switchTheme();
    }
  }
};
</script>

<style scoped lang="scss">
.theme-toggle {
  display: inline-flex;
  vertical-align: middle;
  color: var(--body-text);

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
/* Hide the checkbox */
.hidden-checkbox {
  display: none;
}

.toggle-button {
  background: #e5e7eb;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background 0.3s, transform 0.5s ease-in-out;
}

/* Rotation Effect */
.toggle-button:has(.hidden-checkbox:checked) {
  transform: rotate(180deg);
}

.toggle-button:hover {
  background: #d1d5db;
}

body:has(.hidden-checkbox:checked) .toggle-button {
  background: #374151;
}

body:has(.hidden-checkbox:checked) .toggle-button:hover {
  background: #4b5563;
}

/* Icon Wrapper */
.icon-wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Icon Styles */
.icon {
  position: absolute;
  width: 100%;
  height: 100%;
  stroke: currentColor;
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
}

.moon {
  opacity: 0;
  transform: rotate(-180deg);
}

/* Toggle between sun and moon */
.hidden-checkbox:checked + .toggle-button .sun {
  opacity: 0;
  transform: rotate(180deg);
}

.hidden-checkbox:checked + .toggle-button .moon {
  opacity: 1;
  transform: rotate(0deg);
}
</style>
