<template>
  <div class="tenant-admin-header">
    <div class="container d-flex justify-content-between">
      <icon-logo-header class="tenant-admin-header__logo" />

      <div class="header-buttons">
        <k-button
          class="me-3"
          fill-mode="outline"
          @click="$router.push('/dashboard')"
        >
          Dashboard
        </k-button>

        <k-button @click="showLogoutDialog = true" fill-mode="outline">
          <icon-logout />
          <span>Logout</span>
        </k-button>
      </div>
    </div>

    <k-dialog
      v-if="showLogoutDialog"
      :title="' '"
      @close="showLogoutDialog = false"
    >
      <div class="row">
        <div class="col-12">
          <p class="title d-flex justify-content-center">
            Log out of Transparently.AI
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-2 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="showLogoutDialog = false"
          >
            Cancel
          </k-button>

          <k-button theme-color="primary" @click="logout"> Yes </k-button>
        </div>
      </div>
    </k-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLogoutDialog: false
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header-buttons {
  display: flex;
  align-items: center;
}
</style>
