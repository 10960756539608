<template>
  <div>
    <div class="row header align-items-center mb-6">
      <div class="col-6">
        <h3>Settings</h3>
      </div>
    </div>
    <k-grid
      :data-items="entityList"
      :columns="columns"
      @rowclick="rowClick"
      scrollable="none"
    >
      <template v-slot:actionTemplate="{ props }">
        <td>
          <div
            class="d-flex flex-row justify-content-between align-items-center"
          >
            <k-button
              class="custom-button"
              theme-color="secondary"
              fill-mode="solid"
              rounded="full"
              @click="showEditDialog(props.dataItem)"
              ><icon-edit
            /></k-button>
          </div>
        </td>
      </template>
    </k-grid>
    <k-dialog
      class="dialog-md"
      v-if="dialog.show"
      :title="' '"
      @close="closeDialog"
    >
      <div class="row">
        <div class="col-3"><span>Key</span></div>
        <div class="col-9">
          <k-inputs v-model="entity.key" :disabled="true" />
        </div>
      </div>
      <div class="row">
        <div class="col-3"><span>Type</span></div>
        <div class="col-9 mb-3">
          <k-select
            v-bind="entity.type"
            @change="onChangeType"
            :data="TYPE"
            :valid="true"
          >
          </k-select>
        </div>
      </div>
      <div class="row">
        <div class="col-3"><span>Value</span></div>
        <div class="col-9">
          <k-inputs
            v-if="entity.type == 'STRING'"
            v-model="entity.value"
            :required="true"
            @change="checkValue"
          />
          <k-input-num
            v-if="entity.type == 'NUMERIC'"
            v-model="entity.value"
            :required="true"
            :spinners="false"
            :min="0"
          />
          <div v-if="entity.type == 'TEXT'" class="mb-3">
            <k-editor
              :tools="tools"
              :resizable-content="true"
              :default-content="entity.value"
              style="height: 400px"
              rows="5"
              cols="30"
              @focus="editorValidator"
              @select="editorValidator"
              @blur="editorValidator"
              @change="onChangeEditor"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3"><span>Description</span></div>
        <div class="col-9">
          <k-textarea v-model="entity.description" :rows="5" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <k-button
            v-if="entity.key == BQ_KEY"
            theme-color="secondary"
            class="me-2"
            @click="testConnection"
            >Test Connection
          </k-button>
          <k-button
            theme-color="secondary"
            fill-mode="outline"
            class="me-2"
            @click="closeDialog"
          >
            Cancel
          </k-button>
          <k-button theme-color="primary" @click="save" :disabled="isDataset">
            Save
          </k-button>
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="testConnectionDialog"
      :title="' '"
      @close="testConnectionDialog = false"
    >
      <div class="row mb-3">
        <div class="col-12" v-if="isDataset">
          <div class="fw-bold">
            <icon-warning /> Failed to connect to dataset. Dataset is not found
            / Invalid format.
          </div>
        </div>
        <div class="col-12" v-if="!isDataset">
          <div class="fw-bold"><icon-info /> Connected Successfully</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            v-if="isDataset"
            theme-color="error"
            @click="testConnectionDialog = false"
            >Ok</k-button
          >
          <k-button
            v-if="!isDataset"
            theme-color="success"
            @click="testConnectionDialog = false"
            >Ok</k-button
          >
        </div>
      </div>
    </k-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import IconInfo from '../icons/IconInfo.vue';
import { EDITOR_TOOLS } from '@/shared/constants/editor';

const TYPE = ['NUMERIC', 'TEXT', 'STRING'];
const BQ_KEY = ['BIGQUERY_DATASET'];

export default {
  components: { IconInfo },
  data() {
    return {
      entityList: [],
      entity: { description: null, key: null, type: null, value: null },
      columns: [
        { field: 'key', title: 'Key', width: 200 },
        { field: 'value', title: 'Value', width: 200 },
        { field: 'description', title: 'Description', width: 200 },
        { field: '', title: '', width: 50, cell: 'actionTemplate' }
      ],
      gridClickedRow: {},
      dialog: {
        show: false
      },
      tools: EDITOR_TOOLS,
      isDataset: false,
      testConnectionDialog: false,
      TYPE,
      BQ_KEY
    };
  },
  mounted() {
    this.settingList();
  },
  methods: {
    settingList() {
      axios.post('/setting/list').then((response) => {
        this.entityList = response.data;
      });
    },
    rowClick(event) {
      this.windowVisible = true;
      this.gridClickedRow = event.dataItem;
    },
    showDialog() {
      this.dialog.title = 'Add Company';
      this.dialog.show = true;
    },
    showEditDialog(dataItem) {
      if (dataItem.key == BQ_KEY) {
        this.isDataset = true;
      } else {
        this.isDataset = false;
      }
      if (dataItem.type == 'NUMERIC') {
        dataItem.value = parseInt(dataItem.value);
      }
      this.entity = Object.assign({}, dataItem);
      this.dialog.show = true;

      this.$nextTick(() => {
        let iframe = document.querySelector('.k-content .k-iframe');
        if (iframe) {
          iframe.addEventListener('load', (ev) => {
            const new_style_element = document.createElement('style');
            new_style_element.textContent = 'body { color: #8698ac; }';
            ev.target.contentDocument.head.appendChild(new_style_element);
          });
        }
      });
    },
    closeDialog() {
      this.dialog = {
        show: false
      };
    },
    save() {
      this.editorValidator();
      if (this.entity.value) {
        axios.post('/setting/edit', this.entity).then((response) => {
          if (response.status == 200) {
            this.closeDialog();
            this.settingList();
          }
        });
      }
    },
    testConnection() {
      axios.post('/setting/testConnection', this.entity).then((response) => {
        if (response.status == 200) {
          this.isDataset = !response.data;
          this.testConnectionDialog = true;
        }
      });
    },
    checkValue() {
      if (this.entity.key == BQ_KEY) {
        this.isDataset = true;
      }
    },
    editorValidator() {
      this.$nextTick(() => {
        if (document.getElementById('colEditor')) {
          if (!this.entity.value) {
            document
              .getElementById('colEditor')
              .classList.add('custom-invalid');
          } else {
            document
              .getElementById('colEditor')
              .classList.remove('custom-invalid');
          }
        }
      });
    },
    onChangeEditor(e) {
      this.entity.value = e.html;
    },
    onChangeType(event) {
      this.entity.type = event.value;
    }
  }
};
</script>
