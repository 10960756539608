<script>
import SubscriptionCard from './SubscriptionCard.vue';
import { checkIsAnnuallySubscription } from '@/shared/utils/subscription';

export default {
  components: {
    SubscriptionCard
  },
  props: ['bundles'],
  data: function () {
    return {
      selectedBundleIndex: 0
    };
  },
  created() {
    this.checkIsAnnuallySubscription = checkIsAnnuallySubscription;
  },
  computed: {
    bundleToDisplay() {
      return this.bundles[this.selectedBundleIndex];
    },
    isSelectingMonthlyPayment() {
      return this.selectedBundleIndex === 0;
    },
    isSelectingAnnuallyPayment() {
      return this.selectedBundleIndex === 1;
    }
  }
};
</script>

<template>
  <div :class="['bundle', bundleToDisplay.display]">
    <SubscriptionCard :bundle="bundleToDisplay">
      <template v-slot:title>{{ bundleToDisplay.display }}</template>
      <template v-slot:price>
        <span :class="['interval', bundleToDisplay.display]">
          <span v-if="checkIsAnnuallySubscription(bundleToDisplay)">/year</span>
          <span v-else>/mo</span>
        </span>
        <span class="bundle__price__additional-text">Before tax</span>
        <span v-if="isSelectingAnnuallyPayment" class="bundle__price__discount"
          >20% discount on total price</span
        >
      </template>
      <template v-slot:region>
        <span v-if="Number(bundleToDisplay.regionCount) > 1">Regions</span>
        <span v-else>Region</span>
      </template>
      <template v-slot:additional>
        <div v-if="bundles.length > 1" class="bundle__select-payment">
          <div class="container">
            <div
              :class="[
                'bundle',
                {
                  active: isSelectingMonthlyPayment
                }
              ]"
              @click="selectedBundleIndex = 0"
            >
              <div class="price">Pay Monthly</div>
            </div>
            <div style="width: 20px; padding: 0" />
            <div
              :class="[
                'bundle',
                {
                  active: isSelectingAnnuallyPayment
                }
              ]"
              @click="selectedBundleIndex = 1"
            >
              <div class="price">Pay Annually</div>
              <div class="best-value">Best Value</div>
            </div>

            <div
              :class="[
                'selected',
                {
                  'selected--left': isSelectingMonthlyPayment,
                  'selected--right': isSelectingAnnuallyPayment
                }
              ]"
            />
          </div>
        </div>

        <div class="bundle__additional-text">
          Additional prices apply for the following countries: China, India,
          Japan, UK and US ex-OTC
        </div>
      </template>
      <template v-slot:footer>
        <k-button
          :class="['bundle__button', bundleToDisplay.display]"
          fill-mode="outline"
          @click="$emit('handleGetItNowClick', bundleToDisplay)"
        >
          Get it now
        </k-button>
      </template>
    </SubscriptionCard>
  </div>
</template>

<style lang="scss" scoped>
.bundle {
  &.Standard {
    background: var(--primary);
    color: var(--button-text);

    .bundle__additional-text {
      color: var(--button-text);
    }
  }

  &__price {
    &__additional-text {
      font-size: 16px;
      line-height: 18px;
      display: block;
    }

    &__discount {
      color: rgb(0, 189, 165);
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
    }
  }

  &__additional-text {
    margin-bottom: 12px;
    color: var(--body-text);
  }

  &__select-payment {
    margin: 0 -16px 16px;

    .container {
      background-color: rgb(223, 227, 235);
      border: 1px solid rgb(203, 214, 226);
      width: 100%;
      border-radius: 3000px;
      position: relative;
      padding: 6px 10px;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      cursor: pointer;
      color: #33475b;
    }

    .bundle {
      width: calc(50% - 10px);
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      line-height: initial;
      z-index: 2;
      text-align: center;
      font-size: 11px;
      font-weight: 600;
      color: inherit;

      &.active {
        .price {
          color: rgb(0, 164, 189);
        }
      }
    }

    .price {
      flex: 0;
    }

    .best-value {
      text-transform: uppercase;
      color: rgb(0, 189, 165);
      white-space: nowrap;
    }

    .selected {
      position: absolute;
      height: calc(100% + 6px);
      width: calc(50% + 3px);
      background: rgb(255, 255, 255);
      border-radius: 30000px;
      top: -3px;
      border: 1px solid rgb(0, 164, 189);
      transition: all 0.2s ease-in-out 0s;

      &--left {
        left: -3px;
      }

      &--right {
        left: calc(50% - 3px);
      }
    }
  }
}
</style>
