<template>
  <div>
    <div class="bundle__title">
      <slot name="title"></slot>
    </div>

    <div class="bundle__price">
      <span v-if="bundle && bundle.priceTotal">
        {{ bundle.priceTotal | removeTrailingZeroes }}
      </span>
      <slot name="price"></slot>
    </div>

    <div class="bundle__item-body">
      <div class="bundle__item-count" v-if="bundle">
        <icon-check-bold
          :class="['bundle__item-count__icon', bundle.display]"
        />
        <div class="bundle__item-count__text">
          {{ bundle.seatCount }}
          <span v-if="Number(bundle.seatCount) > 1">Seats</span>
          <span v-else>Seat</span>
        </div>
      </div>

      <div class="bundle__item-count" v-if="bundle">
        <icon-check-bold
          :class="['bundle__item-count__icon', bundle.display]"
        />
        <div class="bundle__item-count__text">
          {{ bundle.regionCount }}
          <slot name="region"></slot>
        </div>
      </div>

      <slot name="service"></slot>

      <div class="fill-flex-gap" />

      <slot name="additional"></slot>
    </div>

    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  props: ['bundle'],
  filters: {
    removeTrailingZeroes(value) {
      return value.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
    }
  }
};
</script>

<style lang="scss" scoped>
.bundle {
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  &__price {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 16px;

    .interval {
      color: #999;
      font-size: 16px;
      margin-left: 4px;

      &.Standard {
        color: rgba(255, 255, 255, 0.78);
      }
    }
  }

  &__description {
    color: #999;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 32px;

    &.Standard {
      color: rgba(255, 255, 255, 0.78);
    }
  }

  &__item-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }

  &__item-count {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    &__icon {
      color: var(--primary);
      margin-right: 16px;
      flex: 0 0 auto;

      &.Standard {
        color: #8bd385;
      }
    }
  }

  &__button {
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 600;

    &.Standard {
      background: var(--body-bg);
      color: var(--primary);
      border-color: var(--primary);
    }

    &--contact {
      text-align: center;
      text-decoration: none;
    }
  }
}
</style>
