<template>
  <k-dialog v-if="isShowing" :title="' '" @close="$emit('onClose')">
    <div class="row mb-3">
      <div class="col-12">
        <slot />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-2">
        <k-button class="me-2" fill-mode="outline" @click="$emit('onClose')"
          >Close</k-button
        >
        <k-button
          v-if="!hideConfirmButton"
          theme-color="primary"
          @click="$emit('onConfirm')"
          >Confirm</k-button
        >
      </div>
    </div>
  </k-dialog>
</template>

<script>
export default {
  props: ['isShowing', 'onClose', 'onConfirm', 'hideConfirmButton']
};
</script>
