<template>
  <div>
    <k-dialog
      v-if="disableUserDialog.dialogOpen"
      :title="' '"
      :class="[
        'k-dialog-wrapper--large',
        {
          'd-none': !disableUserDialog.contentVisible
        }
      ]"
      @close="closeDisableUserDialog"
    >
      <div class="manage-users">
        <div class="row mb-3">
          <div class="col-12 text-center title">Select users to disable</div>
        </div>

        <div class="body container">
          {{ tooltip }}

          <div class="tenant-admin-table mt-2">
            <div class="tenant-admin-table-content">
              <k-grid
                :data-items="dataResult"
                :pageable="pageable"
                :sortable="sortable"
                :sort="sort"
                :skip="skip"
                :take="take"
                :columns="columns"
                scrollable="none"
              >
                <template v-slot:statusTemplate="{ props }">
                  <td>
                    <div
                      v-if="props.dataItem.disabled"
                      class="status-text inactive"
                    >
                      Inactive
                    </div>
                    <div v-else class="status-text active">Active</div>
                  </td>
                </template>

                <template v-slot:actionTemplate="{ props }">
                  <td>
                    <div
                      class="checkbox-wrapper"
                      @click="selectUser(props.dataItem)"
                    >
                      <k-checkbox
                        v-if="props.dataItem.uid !== principal.uid"
                        theme-color="secondary"
                        fill-mode="solid"
                        :checked="disabledList.includes(props.dataItem.uid)"
                      />
                    </div>
                  </td>
                </template>
              </k-grid>
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12 text-center mt-2">
            <k-button
              class="me-2"
              fill-mode="outline"
              @click="closeDisableUserDialog"
            >
              Cancel
            </k-button>

            <k-button
              theme-color="primary"
              @click="showDisableConfirmationDialog"
            >
              Confirm
            </k-button>
          </div>
        </div>
      </div>
    </k-dialog>

    <k-dialog
      v-if="disableConfirmationDialog.show"
      :title="' '"
      @close="closeDisableConfirmationDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            Are you sure you want to disable those users
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeDisableConfirmationDialog"
          >
            Cancel
          </k-button>

          <k-button theme-color="primary" @click="disableUser">
            Confirm
          </k-button>
        </div>
      </div>
    </k-dialog>

    <k-button theme-color="primary" @click="openDisableUserDialog">
      Disable Users
      <k-tooltip id="tooltip" :title="tooltip" :position="'left'">
        <span class="k-icon k-i-info"></span>
      </k-tooltip>
    </k-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { process } from '@progress/kendo-data-query';
import UserService from '@/services/user';

export default {
  name: 'manageUsers',
  mixins: [UserService],
  props: ['tooltip'],
  data() {
    return {
      pageable: false,
      sortable: true,
      skip: 0,
      take: 10,
      sort: [{ field: 'companyId', dir: 'asc' }],
      columns: [
        { field: 'displayName', title: 'NAME' },
        { field: 'email', title: 'EMAIL' },
        { field: '', title: 'STATUS', cell: 'statusTemplate' },
        { field: '', title: 'ACTIONS', width: 90, cell: 'actionTemplate' },
        { field: '', title: '', width: 40 }
      ],
      dataResult: [],
      entityList: [],
      disabledList: [],
      disableUserDialog: {
        dialogOpen: false,
        contentVisible: false
      },
      disableConfirmationDialog: {
        show: false
      }
    };
  },
  computed: {
    ...mapGetters(['principal'])
  },
  async mounted() {
    this.getUserList();
  },
  methods: {
    ...mapActions('fastspring', ['getAccountListOfUser']),
    async getUserList() {
      this.entityList = await this.getAccountListOfUser();
      const dataState = {
        skip: this.skip,
        take: this.take,
        sort: this.sort
      };
      this.dataResult = process(this.entityList, dataState);
    },

    selectUser(dataItem) {
      if (this.disabledList.includes(dataItem.uid)) {
        const userIndex = this.disabledList.findIndex(
          (user) => user === dataItem.uid
        );

        this.disabledList.splice(userIndex, 1);
        return;
      }

      this.disabledList.push(dataItem.uid);
    },

    async disableUser() {
      await this.disableUsers(this.disabledList, this.principal.tenantId);
      this.getUserList();
      this.closeDisableConfirmationDialog();
      this.disableUserDialog.contentVisible = false;
    },

    showDisableConfirmationDialog() {
      this.disableConfirmationDialog.show = true;
    },
    closeDisableConfirmationDialog() {
      this.disableConfirmationDialog = {
        show: false
      };
    },

    openDisableUserDialog() {
      this.disableUserDialog.contentVisible = true;
      this.disableUserDialog.dialogOpen = true;
    },
    closeDisableUserDialog() {
      this.disableUserDialog.contentVisible = false;
      this.disabledList = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  width: fit-content;
  margin: auto;
}
</style>
